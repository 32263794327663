import styled from "styled-components";
import {BET_TYPE, GamePlayer, ROOM_JOIN_STATUS} from "../../dataset";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import moment from "moment";
import ActionTag from "./ActionTag";
import PokerCard from "./PokerCard";
import Const from "../../Const";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";

const Wrapper = styled.div<{ folded: boolean }>`
  position: absolute;
  left: -100%;
  top: -100%;
  width: 54px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    width: 64px;
    height: 78px;
  }


  > div.top {
    width: 100%;
    position: relative;

    > .changeStackSize {
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translateX(-50%);
      font-family: Pretendard;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      border-radius: 4px;
      padding: 2px;
    }

    > .changeStackSize.red {
      color: #FF2525;
    }

    > .changeStackSize.green {
      color: #37FF25;
    }

    .cards {
      ${p => p.folded && `
      opacity: 0.6;
      `};
    }

    > .profileImg {
      border: 2px solid #FFF;
      width: 34px;
      height: 34px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 0 auto;
      ${p => p.folded && `
    opacity: 0.6;
  `};
      @media ${MEDIA_DESKTOP} {
        width: 48px;
        height: 48px;
      }

      &[data-winner=true] {
        border: 2px solid #FFEDAC;
        filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.60));
      }
    }

    .winner-badge {
      position: absolute;
      top: 0;
      right: 0;
      ${p => p.folded && `
    opacity: 0.6;
  `};
      @media ${MEDIA_DESKTOP} {
        top: -4px;
      }
    }
  }

  > div.bottom {
    width: 100%;
    position: relative;
    margin-top: -6px;
    ${p => p.folded && `
    opacity: 0.6;
  `};

    > .profileName {
      border-radius: 5px;
      border-top: 0.4px solid rgba(255, 255, 255, 0.00);

      padding: 2px 4px;
      position: relative;
      z-index: 1;
      text-align: center;
      position: relative;

      > .background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        border-radius: 6px;
        opacity: 0.7;
        background: #181818;
        filter: blur(1px);
        z-index: -1;
      }

      > .nickname {
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 7px;
        border: 0.7px solid #ADADAD;
        background: #000;
        color: #FFF;
        font-family: Pretendard;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.165px;
      }

      > .prize {
        position: relative;
        z-index: 2;
        margin-top: 10px;
        color: ${p => !p.folded ? '#FFFFFF' : 'rgba(255, 255, 255, 0.50)'};
        text-align: center;
        font-family: Pretendard;
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.3px;

        @media ${MEDIA_DESKTOP} {
          font-size: 12px;
        }
      }
    }
  }
`;

const CardDeck = styled.div`
  position: absolute;
  bottom: -8px;
  right: 2px;

  > img {
    width: 50%;
    height: auto;
    box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.40);

    &:first-child {
      transform: translate(3px, 0) rotate(-5deg);
    }

    &:last-child {
      transform: translate(-3px, 0) rotate(5deg);
    }
  }
`;


function HistoryPlayer(
  {
    userId,
    seat,
    nickname,
    profileImg,
    folded,
    winner,
    prize,
    cards,
    changeStackSize
  }: {
    userId: number;
    seat: number;
    nickname: string;
    profileImg: string;
    folded: boolean;
    winner: boolean;
    prize: number;
    cards: number[];
    changeStackSize: number;
  }
): JSX.Element {
  const gameOption = useRecoilValue(gameOptionState);

  return <Wrapper className="history-player" data-seat={seat} data-user={userId} folded={folded}>
    <div className="top">
      {
        changeStackSize !== 0 && <div className={'changeStackSize ' + (changeStackSize > 0 ? 'green' : 'red')}>
          {changeStackSize > 0 ? '+' : ''}{(changeStackSize).toLocaleString()}
        </div>
      }

      <div className="profileImg"
           data-winner={winner}
           style={{backgroundImage: `url(${profileImg})`}}
      />
      {
        cards.length === 2 && (
          <CardDeck>
            <img className='cards' src={`/images/card/style-${gameOption.cardStyle}/${cards[0]}.svg`}/>
            <img className='cards' src={`/images/card/style-${gameOption.cardStyle}/${cards[1]}.svg`}/>
          </CardDeck>
        )
      }
      {
        winner && (
          <img className="winner-badge" src={"/images/winner_badge.svg"}/>
        )
      }
    </div>
    <div className="bottom">
      <div className="profileName">
        <div className="background"/>
        <div className="nickname">{nickname}</div>
        {
          folded ? (
            <div className="prize">FOLD</div>
          ) : (
            <div className="prize">{prize.toLocaleString()}</div>
          )
        }
      </div>
    </div>
  </Wrapper>;
}

export default HistoryPlayer;
