import styled from "styled-components";
import PCMain from "./PC";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Main = () => {
  return <MainWrapper>
    <PCMain/>
  </MainWrapper>
}

export default Main;
