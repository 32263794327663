import React, {useMemo} from "react";
import styled from "styled-components";
import {convertChips, minimumChips} from "../../utils/chip";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{chipLength: number, isBig: boolean}>`
  display: grid;
  position: relative;
  align-content: flex-end;
  justify-content: center;
  grid-template-columns: repeat(12, auto);
  grid-template-rows: repeat(2, auto);
  padding: 0 4px;
  >.col {
    ${p => p.chipLength === 1 ? `
    &:nth-child(1){
       top: -6px;
       left: -4px;
       ${p.isBig ? `
        top: -16px;
        left: -8px;
       `: ``}
    }
    `: ``}
    ${p => p.chipLength === 2 ? `
    &:nth-child(1){
      top: -6px;
      left: 0px;
      ${p.isBig ? `
        top: -16px;
        left: 0px;
       `: ``}
    }
    &:nth-child(2){
      top: -2px;
      left: -8px;
      ${p.isBig ? `
        top: -2px;
        left: -32px;
       `: ``}
    }
    `: ``}
    ${p => p.chipLength === 3 ? `
    &:nth-child(1){
      top: -8px;
      left: 0px;
      ${p.isBig ? `
        top: -32px;
        left: -8px;
       `: ``}
    }
    &:nth-child(2){
      top: -4px;
      left: -8px;
      ${p.isBig ? `
        top: -8px;
        left: -34px;
       `: ``}
    }
    &:nth-child(3){
      top: 0;
      left: 0px;
      ${p.isBig ? `
        top: 0;
        left: 0px;
       `: ``}
    }
    `: ``}
    
  }
`;

//  [1 2 3 4] [5 6 7 8] [9 10 11 12]
// [1 2 3] [4 5 6] [7 8 9] [10 11 12]
const Column = styled.div<{isBig: boolean}>`
  position: absolute;
  line-height: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.3));
  
  > img {
    width: 14px;
    height: 13px;
    z-index: 2;
    object-fit: contain;

    @media ${MEDIA_DESKTOP} {
      width: 20px;
      height: 19px;
      ${p => p.isBig ? `
       width: 45px;
       height: 37px;
      `: ``}
    }
  }
  
  > img:not(:nth-child(1)) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`;

const ChipImage = styled.img<{i: number}>`
  @keyframes chip-down {
    from {
      transform: translateY(-300%);
      opacity: 0;
    }
    
    to {
      opacity: 1;
    }
  }

  transform: translateY(${p => p.i * -11}%);
  animation: chip-down 0.25s ease-in-out ${p => p.i * 0.05}s backwards;
`;

const Chips = ({className, amount, isBig = false}: {
  className?: string,
  amount: number,
  isBig?: boolean
}) => {
  const chips = useMemo(() => minimumChips(amount), [amount]);
  const innerChips = chips.slice(0, 3);
  return <Wrapper isBig={isBig} className={['chips', className].join(' ')} data-amount={amount} chipLength={innerChips.length}>
    {
      innerChips.map(({size, amount}, i) => (
        <Column className='col' key={i} isBig={isBig}>
          {
            Array.from({length: amount}).map((_, i) => (
              <ChipImage key={`${size}${i}`} src={`/images/chips/${convertChips(size)}.png`} i={i} data-chip={size} data-top={i === amount-1}/>
            ))
          }
        </Column>
      ))
    }
  </Wrapper>
}

export default Chips;
