import React, {MouseEventHandler} from "react";
import styled from "styled-components";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";


const Wrapper = styled.div<{
  prev: boolean,
  next: boolean
}>`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 11px;

  @media ${MEDIA_DESKTOP} {
    width: auto;
    margin-top: 0;
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media ${MEDIA_DESKTOP} {
      flex: initial;
      
      &.prev, &.next {
        padding: 12px;
      }
    }

    &.prev {
      ${p => !p.prev && `
        opacity: 0.5;
        pointer-events: none;
        cursor: initial;
      `}
    }

    &.next {
      ${p => !p.next && `
        opacity: 0.5;
        pointer-events: none;
        cursor: initial;
      `}
    }
  }

  .page-indicator {
    font-size: 14px;
    font-weight: 500;
    cursor: initial;
    
    @media ${MEDIA_DESKTOP} {
      font-size: 18px;
      font-weight: 500;
    }
    
    > span {
      &:first-of-type {
        margin-right: 2px;
      }

      &:last-of-type {
        color: #E31D1D;
        margin-left: 2px;
      }
    }
  }
`;

function HistoryPagination(
  {
    current,
    total,
    onClickPrev,
    onClickNext,
    onClickLatest,
  }: {
    current: number;
    total: number;
    onClickPrev: MouseEventHandler;
    onClickNext: MouseEventHandler;
    onClickLatest: MouseEventHandler;
  }
) {
  const orientation = useScreenOrientation();

  return <Wrapper prev={current > 1} next={total > current}>
    <div className="prev" onClick={onClickPrev}>
      <img src="/images/ic_result_prev.svg"/>
    </div>
    <div className="page-indicator" onClick={onClickLatest}>
      {
        orientation === 'portrait' ? <>
          <span>{current}</span>/<span>{total}</span>
        </> : <>
          <span>{current}</span>game
        </>
      }
    </div>
    <div className="next" onClick={onClickNext}>
      <img src="/images/ic_result_next.svg"/>
    </div>
  </Wrapper>
}

export default HistoryPagination;
