import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {client, requestCurrentRooms, requestJoinRoom, useSocketIsConnect} from "../api";
import {useRecoilState, useSetRecoilState} from "recoil";
import {globalLoadingState} from "../recoil/Loading";
import useDialog from "../hooks/useDialog";
import {connectionState} from "../recoil/Connection";

const RejoinCheck: React.FC = () => {
    const history = useHistory();
    const [conn] = useSocketIsConnect();
    const {
      openDialog,
      closeDialog
    } = useDialog();

    const setGlobalLoading = useSetRecoilState(globalLoadingState);
    const [connected, setConnected] = useRecoilState(connectionState);

    useEffect(() => {

      (async () => {
        if (connected) {
          if (!conn.isConnected) {
            const dialogId = openDialog({
              title: '재접속',
              text: '서버와의 연결이 끊겨 재접속 중입니다...',
              confirmText: '연결 취소',
              disableBackdropClick: true,
              onConfirm: () => {
                history.replace('/');
              }
            });

            client.once('connect', () => {
              closeDialog(dialogId);
            });

            setConnected(false);
          }
        } else {
          if (conn.isConnected && conn.isLogin) {
            setGlobalLoading(true);

            const {list: rooms} = await requestCurrentRooms();
            const sortedRooms = rooms.sort((a, b) => a.type - b.type);
            if (sortedRooms.length > 0 && sortedRooms[0].roomId > 0) {
              const roomId = sortedRooms[0].roomId;
              await requestJoinRoom(roomId);
              history.push("/g?id=" + roomId);
            }

            setConnected(true);
            setGlobalLoading(false);
          }
        }
      })();
    }, [connected, conn.isConnected, conn.isLogin]);

    return <>
      {/* <div>conn : {conn.isConnected ? "1":"0"}</div>
    <div>login : {conn.isLogin ? "1":"0"}</div>
    <div>room : {room > 0 ? "1":"0"}</div> */}
    </>;
  }
;
export default RejoinCheck;