import styled from "styled-components";
import {useMemo} from "react";
import {BET_TYPE} from "../../dataset";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{
  act: number;
  show: boolean;
}>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${p => p.show ? `1`: `0`};
  position: absolute;
  background: rgba(16, 16, 18, 0.80);
  text-align: center;
  display: inline-flex;
  height: 12px;
  padding: 4px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #FFF;

  color: #181818;

  span {
    text-align: center;
    font-family: Pretendard;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
  }

  @media ${MEDIA_DESKTOP} {
    height: 32px;
    padding: 4px 8px;
    border-radius: 10px;
    span {
      color: #181818;
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;  
    }
  }


  ${p => p.act === BET_TYPE.FOLD && `
  span {
    color: #FFFFFF;
    filter: drop-shadow(0px 0.3px 0.5px rgba(0, 0, 0, 0.30));
  }
  background: #7E7E7E;
  `};

  ${p => p.act === BET_TYPE.CHECK && `
  span {
    color: #FFFFFF;
    filter: drop-shadow(0px 0.3px 0.5px rgba(0, 0, 0, 0.30));  
  }
  background: #00A7EE;
  `};
  ${p => p.act === BET_TYPE.CALL && `
  span {
    color: #FFFFFF;
    filter: drop-shadow(0px 0.3px 0.5px rgba(0, 0, 0, 0.30));
  }
  background: #FE7C10;
  `};

  ${p => p.act === BET_TYPE.RAISE && `
  span {
    color: #FFFFFF;
    filter: drop-shadow(0px 0.3px 0.5px rgba(0, 0, 0, 0.30));
  }
  background: #04D733;
  `};

  ${p => p.act === BET_TYPE.BET && `
  span {
    color: #FFFFFF;
    filter: drop-shadow(0px 0.3px 0.5px rgba(0, 0, 0, 0.30));  
  }
  background: #7A04D7;
  `};

  ${p => p.act === BET_TYPE.ALLIN && `
  span {
    color: #FFFFFF;
    filter: drop-shadow(0px 0.3px 0.5px rgba(0, 0, 0, 0.30));
  }
  background: #DB0007;  
  `};
  
  ${p => p.act === -99 && ` 
  span {
    color: #181818;
  }
  background: #FFD703;
  `}
`;

function ActionTag({act, show}: {
  act: number,
  show: boolean
}) {
  const typeText = useMemo(() => {
    switch (act) {
      case -99: //Win
        return 'WINNER!'
      case BET_TYPE.FOLD:
        return 'FOLD';
      case BET_TYPE.CALL:
        return 'CALL';
      case BET_TYPE.CHECK:
        return 'CHECK';
      case BET_TYPE.RAISE:
        return 'RAISE';
      case BET_TYPE.ALLIN:
        return 'ALL-IN';
      case BET_TYPE.BET:
        return 'BET';
      case BET_TYPE.SB:
        return 'SB';
      case BET_TYPE.BB:
        return 'BB';
      case BET_TYPE.STR:
        return 'STR';
    }
  }, [act]);

  return <Wrapper show={show} className="action" act={act}>
    <span>{typeText}</span>
  </Wrapper>;
}

export default ActionTag;
