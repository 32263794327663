import "./overrides";
import React, {useLayoutEffect} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {SnackbarProvider} from 'notistack'
import {RecoilRoot} from "recoil";
import theme from './theme';
import Container from './Container';
import RejoinCheck from "./components/RejoinCheck";
import Game from "./pages/Game";
import Main from "./pages/Main";
import FromServerToast from "./components/FromServerToast";

const App: React.FC = () => {
  return (<ThemeProvider theme={theme}>
    <BrowserRouter>
      <RecoilRoot>
        <SnackbarProvider className="snackbar" anchorOrigin={{horizontal: "center", vertical: "top"}}>
          <Container>
            <RejoinCheck/>
            <FromServerToast />
            <Switch>
              <Redirect path="/" exact to={"/m/holdem"}/>
              <Route path={'/m/:type'}>
                <Main/>
              </Route>
              <Route path={'/g'} exact>
                <Game/>
              </Route>
            </Switch>
          </Container>
        </SnackbarProvider>
      </RecoilRoot>
    </BrowserRouter>
  </ThemeProvider>);
};
export default App;
