import styled from "styled-components";
import {useMemo} from "react";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
`;
const HandWrapper = styled.div`
  background: linear-gradient(180deg, #25262D 0%, rgba(37, 38, 45, 0.00) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 20px 0 15px;

  > .title {
    color: #FFF;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  > .hand {
    color: #8D7AFF;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`;
const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  > .description {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.2px;
    text-align: center;

    > .highlight {
      color: #8D7AFF;
    }
  }

  > .sub-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 230px;
    padding: 10px;
    border-radius: 8px;
    background: #25262D;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`;
const SettlementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px 0;
  background: linear-gradient(180deg, #25262D 0%, rgba(37, 38, 45, 0.00) 100%);
  >.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    >.chip {
      padding-top: 4px;
      height: 24px;
      object-fit: contain;
    }
    >.title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      opacity: 0.5;
    }
    >.value {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      >.diff {
        margin-left: 4px;
        color: #FF3D3D;
      }
    }
  }
`;

function LeaveSettlement(
  {
    buyin,
    hands,
    stack,
    lossPercentage,
    settle,
    maxHands,
    winAmount,
    lack
  }: {
    buyin: number,
    hands: number,
    stack: number,
    lossPercentage: number,
    settle: number,
    maxHands: number,
    winAmount: number,
    lack: boolean,
  }
) {
  const lossAmount = useMemo(() => {
    return Math.floor(winAmount * (lossPercentage / 100));
  }, [winAmount, lossPercentage]);

  return <Wrapper>
    <HandWrapper>
      <div className='title'>Game rounds</div>
      <div className='hand'>{hands.toLocaleString()}</div>
    </HandWrapper>
    <DescriptionWrapper>
      <div className='description'>
        Due to insufficient number of games,<br/>
        <span className='highlight'>{lossPercentage}%</span> will be deducted from the revenue and settled.
      </div>
      <div className='sub-description'>
        Information on fee deduction based on number of games<br/>
        ~50 times: -25%<br/>
        51~100 times: -10%<br/>
        No deduction over 100 times
      </div>
    </DescriptionWrapper>
    <SettlementWrapper>
      <div className='row'>
        <div className='title'>proceeds</div>
        <div className='value'>{(winAmount > 0 ? winAmount : 0).toLocaleString()}</div>
      </div>
      <div className='row'>
        <img src='/images/chips.png' className='chip'/>
        <div className='title'>settlement amount</div>
        <div className='value'>
          {settle.toLocaleString()}
          {
            lossAmount > 0 && (
              <span className="diff" data-loss="true">
               ({`-${lossAmount.toLocaleString()})`}
            </span>
            )
          }
        </div>
      </div>
    </SettlementWrapper>
  </Wrapper>
}

export default LeaveSettlement;
