import styled from "styled-components";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {ceremonyCardsState, shiningCardsState} from "../../recoil/Game";
import {CARD_INFO} from "../../dataset";

const Wrapper = styled.div<{isCommunityCard?:boolean; show: boolean}>`
  ${p => p.isCommunityCard ? `
  opacity: ${p.show ? 1: 0} !important;
  ` : ``}
  
  width: 100%;
  height: 100%;
  perspective: 1000px;
  display: inline-block;
  transition: all 0.1s linear;

  &[data-ceremony="true"] {
    filter: brightness(0.5);
    
    &[data-zoomed="true"] {
      filter: none;
      zoom: 1.1;
      box-shadow: 0px 2.2px 2.2px 0px rgba(0, 0, 0, 0.20), 0px 0px 6.6px 0px #FFD764;
    }
  }
`;

const Card = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.5s linear;
  transform-style: preserve-3d;
  will-change: transform;

  &[data-flipped="true"] {
    transform: rotateY(-180deg);
  }

  > img {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotateY(-180deg);
    }
  }
`;

const ShineEffect = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.3, 1.2)
`;

function PokerCard(
  {
    isCommunityCard,
    card,
    flip,
    delay = 0,
    startRemoveAnimation,
    tableStyle,
    ...p
  }: {
    isCommunityCard?: boolean,
    card?: number,
    flip?: boolean,
    delay?: number,
    startRemoveAnimation?: number,
    tableStyle: number,
    [k: string]: any
  }
) {
  const gameOption = useRecoilValue(gameOptionState);
  const shiningCards = useRecoilValue(shiningCardsState);
  const ceremonyCards = useRecoilValue(ceremonyCardsState);
  const [flipped, setFlipped] = useState<boolean>(Boolean(delay === -1 && flip));
  const [shine, setShine] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const isZoomed = useMemo(() => {
    return card !== undefined && ceremonyCards.includes(CARD_INFO[card])
  }, [card, ceremonyCards]);

  const handleShineEnd = useCallback(() => {
    setShine(false);
  }, [])

  useEffect(() => {
    if (card !== undefined && shiningCards.includes(CARD_INFO[card])) {
      setTimeout(() => {
        setShine(true);
      }, 2000);
    }
  }, [card, shiningCards]);

  useEffect(() => {
    if (flip) {
      setTimeout(() => {
        setFlipped(true);
      }, delay || 10);
    } else {
      setFlipped(false);
    }
  }, [flip, delay]);
  useEffect(()=>{
    if(startRemoveAnimation === undefined) return;
    setTimeout(() => {
      setShow(false)
    }, startRemoveAnimation)
  }, [startRemoveAnimation])

  return <Wrapper isCommunityCard={isCommunityCard} show={show} {...p} data-ceremony={ceremonyCards.length > 0} data-zoomed={isZoomed}>
    <Card data-flipped={flipped}>
      <img src={`/images/game/card_back_${tableStyle}.png`}/>
      {
        card !== undefined && (
          <img src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}/>
        )
      }
      {
        shine && (
          <ShineEffect src="/images/effect/made.png" />
        )
      }
    </Card>
  </Wrapper>;
}

export default PokerCard;
