import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {ReactFontSizeByTextLength} from "../../utils/ReactFontSizeByTextLength";
import {fixedNumber, toEasternNumeral} from "../../utils/common";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import Chips from "./Chips";
import {useEffect, useMemo} from "react";
import {minimumChips} from "../../utils/chip";
import {playSFX, Sounds} from "../../utils/sound";

const Wrapper = styled.div`
  position: absolute;
  left: -100%;
  top: -100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PotItems = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 4px;
  text-align: center;
  height: 40px;
  @media ${MEDIA_DESKTOP} {
    height: 50px;
  }

  > div {
    margin: 0 auto;
    min-width: 50px;
    width: fit-content;

    .label {
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      border-radius: 15px;
      background: rgba(24, 26, 29, 0.50);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
      padding: 2px 8px;
      transform: scale(0.9);

      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
        transform: none;
      }
    }
  }
`;

const TotalPot = styled.div<{ isCenter: boolean }>`
  min-width: 95px;
  position: relative;

  ${p => p.isCenter ? `
  padding: 2px 4px 2px 4px;
  ` : `
  padding: 2px 4px 2px 20px;
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(24, 24, 24, 0.50);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.30);
  @media ${MEDIA_DESKTOP} {
    padding-right: 8px;
  }
  > span {
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
    color: white;
  }

  > .chips {
    position: absolute;
    right: initial;
    left: 0;
    top: 50%;
    transform: translateY(calc(-50% + 0px));
    object-fit: contain;
    @media ${MEDIA_DESKTOP} {
      left: -24px;
    }
  }

  @media ${MEDIA_DESKTOP} {
    min-width: 168px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.30);
    font-family: Pretendard;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`;

function FieldPots(
  {
    pots,
    total,
    BB,
    children
  }: {
    pots: number[];
    total: number,
    BB: number;
    children?: any
  }
): JSX.Element {
  const orientation = useScreenOrientation();
  const gameOption = useRecoilValue(gameOptionState);
  const chips = useMemo(() => minimumChips(total), [total]);
  useEffect(() => {
    const allChipCount = chips.reduce((a, v) => a + v.amount, 0);
    for (let i = 0; i < allChipCount; i++) {
      playSFX(Sounds.SFX_FIELD_POT_UP)
    }
  }, [chips]);
  const potSize = useMemo(() => {
    return pots.reduce((a, v) => a + v, 0);
  }, [pots]);

  return <Wrapper className="field-pots">
    <PotItems>
      {
        pots.slice(1, 5).filter(pot => pot > 0).map((amount, i) => (
          <div key={i}>
            <div className="label">
              <ReactFontSizeByTextLength changePerChar={1}>
                <span>{gameOption.alwaysBB ? `${fixedNumber(amount / BB, 2)} BB` : total.toString().toLocaleString()}</span>
              </ReactFontSizeByTextLength>
            </div>
          </div>
        ))
      }
    </PotItems>
    <TotalPot isCenter={total === 0}>
      <Chips isBig={orientation === 'landscape'} className="chips field-pot" amount={total}/>
      <ReactFontSizeByTextLength changePerChar={1}>
        <span>{gameOption.alwaysBB ? `${total / BB} BB` : total.toLocaleString()}</span>
      </ReactFontSizeByTextLength>
    </TotalPot>
    {children}
  </Wrapper>;
}

export default FieldPots;
