import styled from "styled-components";
import {useEffect, useState} from "react";
import * as PIXI from 'pixi.js';
import * as particles from '@pixi/particle-emitter';
import {upgradeConfig} from "@pixi/particle-emitter";
import {useRecoilState} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import useScreenOrientation from "../../hooks/useScreenOrientation";

const ParticleBackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  > .background {
    object-fit: contain;
    width: 110%;
    height: 110%;
    position: absolute;
    padding-top: 30px;
    padding-bottom: 100px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 1;
  }

  > canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
  }
`

interface ParticleBackgroundProps {
  tableStyle: number;
}
const ParticleBackground = ({tableStyle}: ParticleBackgroundProps) => {
  return <ParticleBackgroundWrapper id='fsfs'>
    <canvas id="c2" style={{
      opacity: 1,
      background: '#000'
    }}/>
  </ParticleBackgroundWrapper>
}

export default ParticleBackground;
