import {RAKE_TYPE, ROOM_TYPE} from "../../dataset";
import React, {useMemo} from "react";
import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  color: #FFF;
  text-align: right;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;

  @media ${MEDIA_DESKTOP} {
    color: #FFF;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`;

function StatusBoard(
  {
    roomType,
    SB,
    BB,
    ante,
    rakeType,
    rake,
    myRank,
    totalMember,
    straddle,
    timerText,
    blindUpTimerText
  }: {
    roomType: ROOM_TYPE;
    SB: number;
    BB: number;
    ante: number;
    rakeType: RAKE_TYPE;
    rake: number;
    myRank: number;
    totalMember: number;
    straddle: boolean;
    timerText: string;
    blindUpTimerText: string;
  }
) {

  const ringBetText = useMemo<string>(() => {
    let chunks = [];

    if (SB > 0 && BB > 0) {
      chunks.push(`${SB.toLocaleString()} / ${BB.toLocaleString()}`);
    }
    if (ante > 0) {
      chunks.push(`Ante ${ante.toLocaleString()}`);
    }

    return chunks.join(', ');
  }, [SB, BB, ante]);

  const rakeText = useMemo<string>(() => {
    if (rakeType === RAKE_TYPE.HAND_RAKE) {
      return `핸드레이크 ${rake.toLocaleString()}`;
    } else if (rakeType === RAKE_TYPE.POT_RAKE) {
      return `팟레이크 ${rake}%`;
    }

    return '';
  }, [rakeType, rake]);

  return <Wrapper className="status-board">
    {
      roomType == ROOM_TYPE.TOURNAMENT && timerText && <>
        <div>{timerText}</div>
        <div>{blindUpTimerText}</div>
        {
          ringBetText.length > 0 && (
            <div>{ringBetText}</div>
          )
        }
        {
          Boolean(myRank && totalMember) && (
            <div>My Rank {myRank} / {totalMember}</div>
          )
        }
      </>
    }
    {
      roomType == ROOM_TYPE.RING && <>
        {
          ringBetText.length > 0 && (
            <div>{ringBetText}</div>
          )
        }
        {/*{
          rakeText.length > 0 && (
            <div>{rakeText}</div>
          )
        }*/}
        {
          Boolean(straddle) && (
            <div>With straddle</div>
          )
        }
      </>
    }
  </Wrapper>;
}

export default StatusBoard;
