import styled from "styled-components";
import Flex1 from "./common/Flex";
import {useCallback, useEffect, useMemo, useState} from "react";
import {MyInfo} from "../dataset";
import {requestMyInfo} from "../api";
import BuyInGaugeHorizontal from "./game/BuyInGaugeHorizontal";
import {debounce} from "lodash";

const Wrapper = styled.div`
  width: 310px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  
`;

const BorderRect = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid var(--Point-Red, #E31D1D);
  background: transparent;
  box-shadow: 0px 0px 16px 2px #E31D1D inset;
`

const Title = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  background: rgba(227, 29, 29, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;

  > .close-wrapper {
    z-index: 8;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;

    > .background {
      width: 32px;
      height: 32px;
      padding-top: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }

    > .close {
      width: 24px;
      height: 24px;
      object-fit: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;


const BuyInButton = styled.div<{
  disabled?: boolean
}>`
  z-index: 8;
  width: 120px;
  height: 48px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
  ${p => p.disabled && `
    color: rgba(255, 255, 255, 0.5);
    background: #25262D;
    cursor: initial;
  `};
`;

const MinMaxBuyInWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 68px;
  background: linear-gradient(0deg, #25262D 0%, rgba(37, 38, 45, 0.00) 100%);

  > .value-col {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;

    > .title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
    }

    > .value {
      color: var(--Point-Red, #E31D1D);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
    }
  }

  > .current-value {
    caret-color: white;
    width: 144px;
    border: none;
    padding: 0;
    outline: none;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
    background: linear-gradient(180deg, #FFF 4.69%, #818181 19.27%, #FFF 46.35%, #818181 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
const BuyInAfterWrapper = styled.div`
  background: linear-gradient(180deg, #25262D 0%, rgba(37, 38, 45, 0.00) 100%);
  width: 100%;
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;

  > .title {
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    opacity: 0.5;
  }

  > .value {
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`

interface BuyInModalProps {
  bigBlind: number;
  minBuyIn: number;
  maxBuyIn: number;
  onClickBuyIn: (buyIn: number) => void;
  onClose: () => void;
}

const SliderWrapper = styled.div`
  padding: 10px 16px 20px;
  width: 100%;
  height: 78px;
  display: flex;
  z-index: 8;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .plus-minus-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      object-fit: contain;
    }
  }

  > .slider {
    height: 28px;
    width: 240px;
  }
`

function BuyInModal(
  {
    bigBlind,
    minBuyIn,
    maxBuyIn,
    onClickBuyIn,
    onClose
  }: BuyInModalProps
) {
  const [value, setValue] = useState<number>(minBuyIn);
  const [myInfo, setMyInfo] = useState<MyInfo>();

  const isValidValue = useMemo<boolean>(() => {
    if (!value || value < minBuyIn || value > maxBuyIn) {
      return false;
    } else if (!myInfo || myInfo.money < value) {
      return false;
    }

    return true;
  }, [value, minBuyIn, maxBuyIn, myInfo?.money]);

  useEffect(() => {
    requestMyInfo().then((v: any) => setMyInfo(v.info));
  }, []);

  const handleInput = useCallback((e: any) => {
    if (e.nativeEvent?.data && (/[^0-9]/g).test(e.nativeEvent.data)) {
      e.preventDefault();
      return;
    }

    const value = (e.target?.value ?? 0).toString().replace(/[^0-9]/g, '')
    setValue(Number(value))
  }, []);

  const handleBuyIn = () => {
    console.log('handleBuyIn', value, isValidValue);
    if (!isValidValue)
      return;

    onClickBuyIn && onClickBuyIn(Number(value));
  }

  const handleSum = (num: number) => {
    let newValue;

    if (myInfo && num === -1) {
      newValue = myInfo?.money;
    } else {
      newValue = Number(value || 0) + num;
    }

    setValue(Math.min(maxBuyIn, newValue));
  }

  return <Wrapper>
    <BorderRect/>
    <Title>BUYIN
      <div className='close-wrapper'>
        	<img className='background' src='/images/close-bg.png'/>
        <img className='close' src='/images/Close.png' onClick={onClose}/>
      </div>
    </Title>
    <MinMaxBuyInWrapper>
      <div className="value-col">
        <div className="title">MIN</div>
        <div className="value">{minBuyIn.toLocaleString()}</div>
      </div>
      <input className="current-value" type="text" value={value.toLocaleString()} onChange={e => {
        const value = Number(e.target.value.replace(/[^0-9]/g, ''));
        debounce(() => setValue(value), 100)()
      }}/>
      <div className="value-col">
        <div className="title">MAX</div>
        <div className="value">{maxBuyIn.toLocaleString()}</div>
      </div>
    </MinMaxBuyInWrapper>
    <SliderWrapper>
      <div className='plus-minus-wrapper'>
        <img src='/images/Minus.png'/>
        <img src='/images/Plus.png'/>
      </div>
      <div className='slider'>
        <BuyInGaugeHorizontal min={minBuyIn} max={Math.min((myInfo?.money ?? 0), maxBuyIn)} value={value} onChange={setValue}/>
      </div>
    </SliderWrapper>
    <BuyInAfterWrapper>
      <div className='title'>
        Amount remaining after buy-in
      </div>
      <div className='value'>
        {(((myInfo?.money ?? 0) - value) < 0 ? 0 : (myInfo?.money ?? 0) - value).toLocaleString()}
      </div>
    </BuyInAfterWrapper>
    <BuyInButton disabled={!isValidValue} onClick={handleBuyIn}>Buyin</BuyInButton>
  </Wrapper>
}

export default BuyInModal
