import $ from "jquery"

let loading_count = 0;
export function startLoading(){
    loading_count++;

    let loading = $(`<div class="loading-modal" style="opacity:0">
        <div class="loading-indicator">
            <div class="cube">
                <div class="sides">
                    <div style="background-image: url(/images/img_profile_logo_loading.png);" class="top"></div>
                    <div style="background-image: url(/images/img_profile_logo_loading.png1);" class="right"></div>
                    <div style="background-image: url(/images/img_profile_logo_loading.png1);" class="bottom"></div>
                    <div style="background-image: url(/images/img_profile_logo_loading.png1);" class="left"></div>
                    <div style="background-image: url(/images/img_profile_logo_loading.png1);" class="front"></div>
                    <div style="background-image: url(/images/img_profile_logo_loading.png1);" class="back"></div>
                </div>
            </div>
        </div>
    </div>`)
    
    $(document.body).append(loading);
    setTimeout(function(){
        $(loading).css("opacity",1)
    },1)
    return loading;
}

export function stopLoading(loading:any){
    loading_count--;

    $(loading).css("opacity",0)
    setTimeout(function(){
        loading.remove();
    },500)
}