import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled, {keyframes} from "styled-components";
// @ts-ignore
import {Hand} from "pokersolver";
import {BET_TYPE, CARD_INFO, GamePlayer, ROOM_JOIN_STATUS, winnerModel} from "../../dataset";
import ActionTag from "./ActionTag";
import PokerCard from "./PokerCard";
import Const from "../../Const";
import moment from "moment/moment";

import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {parseDatetime} from "../../constants/moment";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {ReactFontSizeByTextLength} from "../../utils/ReactFontSizeByTextLength";
import {fixedNumber} from "../../utils/common";
import WinningRate from "./WinningRate";
import Emoticon from "./Emoticon";
import {betLeftSecState, emoticonByUserId} from "../../recoil/Game";
import {playSFX, Sounds} from "../../utils/sound";


const TimerProgressWrapper = styled.div<{ percent: number }>`
  width: calc(100% + 28px);
  height: calc(100% + 28px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  @media ${MEDIA_DESKTOP} {
    width: calc(100% + 56px);
    height: calc(100% + 56px);
  }
`

interface TimerProgressProps {
  percent: number;
}

const TimerProgress = ({percent}: TimerProgressProps) => {
  const circumference = 2 * Math.PI * 31;
  const offset = (percent / 100) * circumference;
  let color = "#42FF00"
  if (percent <= 50) {
    color = "#F90"
  }
  if (percent <= 25) {
    color = "#ED2E06"
  }
  const strokeWidth = 4;

  return <TimerProgressWrapper percent={percent}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" fill="none">
      <circle
        cx="44"
        cy="44"
        r="31"
        opacity="0.5"
        stroke="#181818"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        shapeRendering="crispEdges"
      />
      <g
        filter="url(#filter0_d_1021_110961)">
        <circle
          transform="rotate(-90 44 44)"
          cx="44"
          cy="44"
          r="31"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          shapeRendering="crispEdges"
          strokeDasharray={circumference}
          strokeDashoffset={offset - circumference}
          id="progress"
          style={{
            transition: 'stroke-dashoffset 1s linear, stroke 1s linear'
          }}
        />
      </g>
      <defs>
        <filter id="filter0_d_1021_110961" x="0.5" y="0" width="88" height="88" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0 0 0 0 0.8 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1021_11096"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1021_11096" result="shape"/>
        </filter>
      </defs>
    </svg>
  </TimerProgressWrapper>
}

const CardDeck = styled.div<{
  zoom: boolean
  me: boolean
  show: boolean
  idx: number
}>`
  display: ${p => p.show ? 'flex' : 'none'};
  position: absolute;
  width: 30px;
  ${p => p.idx === 1 && `
  bottom: 8px;
  right: initial;
  left: 64px;
  @media ${MEDIA_DESKTOP} {
    bottom: unset;
    top: -77px;
    left: 150px;    
  }
  `}
  ${p => p.idx === 2 && `
  top: -23px;
  right: initial;
  left: 64px; 
  @media ${MEDIA_DESKTOP} {
    bottom: unset;
    top: 60px;
    left: 175px;    
  } 
  `}
  ${p => p.idx === 3 && `
  bottom: 1px;
  right: initial;
  left: 64px;  
  @media ${MEDIA_DESKTOP} {
    bottom: -85px;
    left: 180px;    
  }
  `}
  ${p => p.idx === 4 && `
  bottom: -50px;
  right: initial;
  left: 38px;  
  @media ${MEDIA_DESKTOP} {
    bottom: -110px;
    left: 5px;    
  }
  `}
  ${p => p.idx === 5 && `
  bottom: 1px;
  right: 70px;
  left: initial;  
  @media ${MEDIA_DESKTOP} {
    right: 75px;
    bottom: -115px;
  }
  `}
  ${p => p.idx === 6 && `
  top: -23px;
  right: 70px;
  left: initial;
  @media ${MEDIA_DESKTOP} {
    right: 170px;
    top: 70px;
  }
  `}
  ${p => p.idx === 7 && `
  bottom: 8px;
  right: 70px;
  left: initial;
  @media ${MEDIA_DESKTOP} {
    right: 160px;
    top: -80px;
  }  
  `}
  ${p => p.idx === 8 && `
  top: -12px;
  right: 84px;
  left: initial;
  @media ${MEDIA_DESKTOP} {
    right: 160px;
    top: -40px;
  }    
  `}

  ${p => p.me ? `
    width: 100%;
    right: initial;
    left: 90px;
    bottom: -10px;
    @media ${MEDIA_DESKTOP} {
      bottom: initial;
      top: 30px;
      left: 230px;
    }
  ` : `
  `}

  ${p => p.zoom && `
    top: initial;
    transition: transform 0.1s linear;
    transform: scale(1.7);
    transform-origin: bottom right;
    right: 0;
    left: 22px;
    bottom: 12px;
  `};
  z-index: 5;

  @media ${MEDIA_DESKTOP} {
    ${p => p.zoom && `
      transform: scale(1.3);
      top: unset;
      left: 50px;
      right: unset;
      bottom: 30px;
    `};

    ${p => p.me ? `
    width: 140px;
    ` : `
    width: 86px;
    `}
  }

  .player-card {
    width: 15px;
    height: 20px;
    transition: all 0.5s;
    opacity: 0;

    ${p => p.me && `
      width: 100px !important;
      height: auto !important;
    `};

    ${p => p.me ? `
    &:nth-child(1) {
      transform: scale(1.1) translate(-6px, 0);
    }

    &:nth-child(2) {
      transform: scale(1.1) translate(0px, 0);
    }
    @media ${MEDIA_DESKTOP} {
      &:nth-child(1) {
        transform: scale(1.1) translate(-10px, 0);
      }

      &:nth-child(2) {
        transform: scale(1.1) translate(0px, 0);
      }
    }
    ` : `
    ${p.zoom ? `
      &:nth-child(1) {
        transform: scale(1.4) translate(5px,0px) rotate(-9deg);
      }
      &:nth-child(2) {
        transform: scale(1.4) translate(0px, -2px) rotate(9deg);
      }
      @media ${MEDIA_DESKTOP} {
        &:nth-child(1) {
          transform: scale(1.8) translate(0px,0px) rotate(-9deg);
        }
  
        &:nth-child(2) {
          transform: scale(1.8) translate(5px, -2px) rotate(9deg);
        }
      }
    ` : `
      &:nth-child(1) {
        transform: scale(1.1) translate(5px, -2px);
      }
      &:nth-child(2) {
        transform: scale(1.1) translate(0px, 0);
      }
    `}
    
    `};
    @media ${MEDIA_DESKTOP} {
      width: 36px;
      height: 47px;
    }
  }
`;

const Wrapper = styled.div<{
  me?: boolean
}>`
  width: ${p => p.me ? 72 : 64}px;
  height: 80px;
  position: absolute;
  left: -100%;
  top: -100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #FFF;
  cursor: pointer;

  @keyframes scaleAnimation {
    0% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1.03);
    }
    100% {
      transform: scale(1.1);
    }
  }

  &[data-winner="true"] {
    z-index: 7;
  }

  @media ${MEDIA_DESKTOP} {
    ${p => p.me ? `
      width: 196px;
      height: 240px;
    ` : `
      width: 156px;
      height: 183px;
    `}
  }

  &[data-fold="true"] {
    .player-wrapper {
      opacity: 0.6;
      transition: opacity 0.2s linear 1s, filter 0.2s linear 0.5s;

      // TODO: 윈도우 크롬 버그때문에 강제로 리페인팅 하도록 애니메이션 적용함.
      // 버그 해결되면 이부분 지워야 함.. 퍼포먼스 문제 있을 수 있음.

      &::before {
        position: absolute;
        width: 1px;
        height: 1px;
        content: ' ';
        animation: BugSolver 1s infinite;
        background-color: black;
        will-change: transform;

        @keyframes BugSolver {
          to {
            transform: rotate(0.1deg);
          }
        }
      }

      .profileImg {
        /*border: none !important;*/
      }
    }
  }

  > .player-wrapper {
    ${p => p.me ? `
      width: 79px;
    ` : `
      width: 65px;
    `};
    @media ${MEDIA_DESKTOP} {
      ${p => p.me ? `
        width: 196px;
      ` : `
        width: 156px;
      `};
    }

    > div.top {
      width: 100%;
      position: relative;

      > .action {
        bottom: 12px;
        right: 0;
        z-index: 10;
        @media ${MEDIA_DESKTOP} {
          bottom: 28px;
        }
      }

      > .profile-image-wrapper {
        transition: all 0.2s linear;
        position: relative;
        width: ${p => p.me ? 70 : 56}px;
        height: ${p => p.me ? 70 : 56}px;
        margin: 0 auto;
        @media ${MEDIA_DESKTOP} {
          width: ${p => p.me ? 168 : 136}px;
          height: ${p => p.me ? 168 : 136}px;
        }

        > .chipleader-wrapper.show {
          opacity: 1;
        }

        > .chipleader-wrapper {
          pointer-events: none;
          z-index: 4;
          transition: opacity 0.2s linear 0.5s;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          > img {
            position: absolute;
          }

          > .hat {
            ${p => p.me ? `
            top: -18px;
            left: 5px;
            width: 38px;
            height: 38px;
            ` : `
            top: -15px;
            left: 5px;
            width: 32px;
            height: 32px;
            `} @media ${MEDIA_DESKTOP} {
            ${p => p.me ? `
              top: -45px;
              left: 15px;
              width: 100px;
              height: 100px;
              ` : `
              top: -40px;
              left: 15px;
              width: 80px;
              height: 80px;
              `}
          }
          }

          > .light-1 {
            ${p => p.me ? `
              width: 46px;
              height: 46px;
              top: -2px;
              right: -10px;
            ` : `
              width: 38px;
              height: 38px;
              top: 0px;
              right: -10px;
            `}
          }

          > .light-2 {
            ${p => p.me ? `
              width: 30px;
            height: 30px;
            top: 26px;
            left: -4px;
            ` : `
              width: 28px;
              height: 28px;
              top: 20px;
              left: -4px;
            `}

          }

          > .light-3 {
            ${p => p.me ? `
              width: 28px;
            height: 28px;
            bottom: -1px;
            right: 2px;
            ` : `
              width: 26px;
              height: 26px;
              bottom: 0px;
              right: 2px;
            `}
          }
        }

        > .profileImg {
          ${p => p.me ? `
            border: 3px solid #FFF;
          ` : `
            border: 2px solid #FFF;
          `};
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          border-radius: 50%;
          transition: all 0.2s linear;

          &[data-winner=true] {
            transition: border 0.2s linear;
            border: 4px solid #FFEDAC;
            filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.60));
          }
        }
      }

      > .profile-image-wrapper.timer {
        z-index: 3;
        margin-bottom: 14px;
        transform: scale(1.1);
      }

      > .profile-image-wrapper.timer-animation {
        animation: scaleAnimation 1s infinite;
      }
    }

    > div.bottom {
      position: relative;
      z-index: 6;
      bottom: 6px;
      ${p => p.me ? `
        width: 79px;
        height: 30px;
        ` : `
        width: 75px;
        height: 30px;
        `};
      padding: 2px 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      @media ${MEDIA_DESKTOP} {
        ${p => p.me ? `
          width: 196px;
          height: 60px;
        ` : `
          width: 156px;
          height: 50px;
        `};
        padding: 5px 8px;
      }

      > .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 6px;
        opacity: 0.7;
        background: #181818;
        filter: blur(1px);
        z-index: 0;
        @media ${MEDIA_DESKTOP} {
          border-radius: 14.4px;
        }
      }

      > .name {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -3px;
        color: #FFF;
        font-family: Pretendard;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.165px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 14px;
        padding: 0px 5px;
        border-radius: 7px;
        border: 0.7px solid #ADADAD;
        background: #000;
        @media ${MEDIA_DESKTOP} {
          top: -16px;
          color: #FFF;
          font-family: Pretendard;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.396px;
          padding: 0px 10px;
          height: 34px;
          border-width: 1.7px;
          border-radius: 16.8px;
        }
      }

      > .row {
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        > .stack {
          color: #FFF;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.3px;
          @media ${MEDIA_DESKTOP} {
            color: #FFF;
            font-family: Pretendard;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.72px;
          }
        }

        > .vpip {
          color: #E31D1D;
          font-family: Pretendard;
          font-size: 8px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.165px;
          @media ${MEDIA_DESKTOP} {
            padding-top: 4px;
            font-family: Pretendard;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.396px;
          }
        }
      }

      > .vpip {
        position: absolute;
        width: 16px;
        height: 16px;
        font-size: 10px;
        font-weight: 500;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #3A3A3A;
        transform: translateY(-25px);
        z-index: 2;

        @media ${MEDIA_DESKTOP} {
          width: 24px;
          height: 24px;
          font-size: 12px;
        }
      }

      > .profileName {
        padding: 5px 5px;
        transform: translateY(-15px);
        transition: all 0.1s linear;
        position: relative;
        z-index: 1;

        @media ${MEDIA_DESKTOP} {
          padding: 6px 10px;
        }

        > .nickname {
          font-size: 10px;
          font-weight: 400;

          @media ${MEDIA_DESKTOP} {
            font-size: 12px;
          }
        }

        > .stack {
          color: #2F99FB;
          text-align: center;
          font-size: 12px;
          font-weight: 600;

          @media ${MEDIA_DESKTOP} {
            font-size: 16px;
          }
        }

        .time-guage {
          height: 4px;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 0;
          border-radius: 0 0 5px 5px;
          overflow: hidden;

          &::after {
            transition: all 1s linear;
          }
        }
      }
    }
  }
`;
const HandRanking = styled.div<{ me?: boolean }>`
  position: absolute;
  bottom: -17px;
  left: 50%;
  min-width: 110%;
  transform: translateX(calc(-50% - 2px));
  padding-right: 4px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  white-space: nowrap;

  border-radius: 7px;
  border: 0.7px solid #ADADAD;
  background: var(--Black, #181818);

  ${p => p.me && `
    bottom: -4px;
    width: 120%;
    transform: translateX(-50%) translateY(100%) scale(0.9);
  `};
  @media ${MEDIA_DESKTOP} {
    bottom: -30px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    border-radius: 22px;
    transform: translateX(calc(-50% - 6px));
  }
`;

const SeatButton = styled.div<{
  disabled: boolean;
  bottomPadding: number;
}>`
  width: 59px;
  height: 59px;
  cursor: pointer;
  position: relative;

  > .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.165px;
  ${p =>( p.bottomPadding === 0 || p.bottomPadding === 4 )? `
    height: 90px;
    padding-bottom: 30px;
    >.background {
      top: -15px;
    }
  ` : ``};
  @media ${MEDIA_DESKTOP} {
    width: 80px;
    height: 80px;
    font-size: 16px;
    ${p => p.bottomPadding === 0 ? `
    padding-bottom: 76px;
    >.background {
      
      top: -37px;
    }
  ` : ``};
  };
  ${p => p.disabled && `
    display: none;
  `};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

const Timer = styled.div<{
  afk: boolean
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 6px 12px rgba(0, 0, 0, 0.40);
  font-size: 16px;
  font-weight: 600;
  pointer-events: none;
  ${p => p.afk && `
    transform: scale(0.8);
    
    
    @media ${MEDIA_DESKTOP} {
      transform: scale(1.2);
    }
  `};

  @media ${MEDIA_DESKTOP} {
    font-size: 24px;
    padding-top: 10px;
  }
`;


const PrizeText = styled.div`
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
  font-size: 12px;
  font-weight: 900;
  background: linear-gradient(180deg, #FFF 0%, #FFC92C 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 2px 4px black);
  z-index: 7;

  @media ${MEDIA_DESKTOP} {
    font-size: 26px;
  }
`;

const AllInFire = styled.img`
  position: absolute;
  width: 100%;
  top: -4px;
  left: 0;
  transform: scale(1.13);
  @media ${MEDIA_DESKTOP} {
    top: -8px;
    transform: scale(1.13);
  }
`;

const WinnerRotate = keyframes`
  from {
    transform: scale(2) rotate(0);
  }
  to {
    transform: scale(2) rotate(360deg);
  }
`;

const WinnerLight = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scale(2.5);
`;

const StyledWinningRate = styled(WinningRate)`
  position: absolute;
  left: 0;
  bottom: 23px;
  width: 100%;
  z-index: 5;
  @media ${MEDIA_DESKTOP} {
    bottom: 60px;
  }
`;

const EmoticonButton = styled.div`
  position: absolute;
  left: 0;
  bottom: -20px;
  transform: translateX(-100%);
  margin-left: -8px;
  cursor: pointer;

  > img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    @media ${MEDIA_DESKTOP} {
      width: 48px;
      height: 48px;
    }
  }
`;

const EmoticonWrapper = styled.div<{me?: boolean}>`
  position: absolute;
  left: 50%;
  bottom: 20px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  z-index: 7;
  @media ${MEDIA_DESKTOP} {
    bottom: ${p => p.me ? `60px;`: `40px;`};
  }
`;

function Player(
  {
    tableStyle,
    isAllIn,
    blindPos,
    isChipLeader,
    idx,
    player,
    act,
    cards,
    betNow,
    winners,
    rate,
    disabled,
    communityCards,
    me,
    BB,
    onClickSeat,
    onClickProfile,
    onClickEmoticon,
    maxTableMember
  }: {
    tableStyle: number;
    isAllIn: boolean;
    blindPos: 'SB' | 'BB' | null,
    isChipLeader: boolean;
    idx: number;
    player?: GamePlayer;
    act?: number;
    cards: number[];
    betNow?: boolean;
    winners?: winnerModel[];
    rate?: number;
    disabled?: boolean;
    communityCards: number[];
    me?: boolean;
    BB: number;
    onClickSeat?: (s: number) => void;
    onClickProfile?: (userId: number) => void;
    onClickEmoticon?: () => void;
    maxTableMember: number;
  }
): JSX.Element {
  const timerRef = useRef<HTMLDivElement>(null);
  const gameOption = useRecoilValue(gameOptionState);
  const [isAfk, setAfk] = useState<boolean>(false);
  const emoticonId = useRecoilValue(emoticonByUserId(player?.userId));
  const betLeftSec = useRecoilValue(betLeftSecState);
  const [innerCommunityCards, setInnerCommunityCards] = useState<number[]>([]);
  useEffect(() => {
    if (isChipLeader) {
      playSFX(Sounds.SFX_CHIP_LEADER)
    }
  }, [isChipLeader]);
  useEffect(() => {
    setTimeout(() => {
      setInnerCommunityCards(communityCards)
    }, communityCards.length === 0 ? 0 : 1800)
  }, [communityCards]);
  const orientation = useScreenOrientation();

  const isSeated = useMemo<boolean>(() => {
    return !!player;
  }, [player]);

  const leftSec = useMemo<number | undefined>(() => {
    if (!betNow) {
      return;
    }
    return betLeftSec;
  }, [betNow, betLeftSec]);

  const [winner, prize] = useMemo<[boolean, number]>(() => {
    const ret: [boolean, number] = [false, 0];
    if (!player || !winners || !Array.isArray(winners)) {
      return ret;
    }

    const idx = winners.findIndex(x => x.userId === player?.userId);
    if (idx !== -1) {
      ret[0] = true;
      ret[1] = player.stackSize - player?.prevStackSize;
    }

    return ret;
  }, [player?.userId, winners, player?.prevStackSize]);

  const {
    isPlaying,
    isFold,
    isObserve
  } = useMemo(() => {
    const status = player?.status
    return {
      isFold: act === BET_TYPE.FOLD || status === ROOM_JOIN_STATUS.FOLD,
      isPlaying: status === ROOM_JOIN_STATUS.PLAYING || status === ROOM_JOIN_STATUS.FOLD,
      isObserve: status === ROOM_JOIN_STATUS.OBSERVE || status === ROOM_JOIN_STATUS.BUYIN_READY,
    };
  }, [player?.status, act]);

  const showActionTag = useMemo(() => {
    return !betNow && !winner;
  }, [betNow, winner]);

  const showTimer = useMemo(() => {
    return !!player?.leaveRoomTime || betNow;
  }, [player?.leaveRoomTime, betNow]);

  const showCards = useMemo(() => {
   return Array.isArray(cards) && cards[0] !== undefined && cards[1] !== undefined;
  }, [cards]);
  const handRankingText = useMemo<string>(() => {
    if (cards.length === 2) {
      return Hand.solve([
        ...cards.map(x => CARD_INFO[x]),
        ...innerCommunityCards.map(x => CARD_INFO[x])
      ]).descr;
    } else {
      return '';
    }
  }, [cards, innerCommunityCards]);

  const emoticonSize = useMemo<number>(() => {
    if (orientation === 'landscape') {
      return me ? 186 : 156;
    } else {
      return me ? 92 : 72;
    }
  }, [me, orientation]);

  const handleClickProfile = useCallback(() => {
    if (player) {
      onClickProfile && onClickProfile(player.userId);
    }
  }, [me, player, onClickProfile]);

  const handleClickSeat = useCallback(() => {
    playSFX(Sounds.SFX_WINDOW_OPEN)
    onClickSeat && onClickSeat(idx);
  }, [idx, onClickSeat]);

  const handleClickEmoticon = useCallback(() => {
    if (me && !emoticonId) {
      onClickEmoticon && onClickEmoticon();
    }
  }, [me, emoticonId, onClickEmoticon]);

  // 장시간 미입력 대기실 이동 타이머
  useEffect(() => {
    if (player?.leaveRoomTime === 'forceFold') {
      setAfk(true);
      return;
    } else {
      setAfk(false);
    }

    if (!player?.leaveRoomTime) {
      return;
    }

    const interval = setInterval(() => {
      if (!timerRef.current) {
        return;
      }

      const leaveRoomTime = parseDatetime(player?.leaveRoomTime);
      const leftSec = leaveRoomTime.unix() - moment().unix();
      if (leftSec >= 0 && timerRef.current.innerText !== leftSec.toString()) {

        timerRef.current.innerText = leftSec.toString();
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [player?.leaveRoomTime]);

  // 내 차례 타이머
  useEffect(() => {
    if (!timerRef.current) {
      return;
    } else if (!showTimer || !betNow || betLeftSec === undefined) {
      return;
    }

    //timerRef.current.innerText = Math.max(betLeftSec - 1, 0).toString()
  }, [showTimer, betNow, betLeftSec]);

  // 이모티콘 소리 재생
  useEffect(() => {
    if (emoticonId) {
      playSFX(`emoji${emoticonId.toString().padStart(2, '0')}.wav`, true);
    }
  }, [emoticonId]);


  const [innerShowActionTag, setInnerShowActionTag] = useState<boolean>(false)
  const [innerAct, setInnerAct] = useState<number>(-1);
  useEffect(() => {
    if (act && showActionTag) {
      setTimeout(() => {
        setInnerAct(act)
        setInnerShowActionTag(true)
      }, 100)
    } else {
      setInnerShowActionTag(false)
      setTimeout(() => {
        setInnerAct(-1)
      }, 100)
    }
  }, [showActionTag, act, rate]);

  useEffect(() => {
    if (act === BET_TYPE.ALLIN && winner) {
      playSFX(Sounds.SFX_ON_FIRE)
    }
  }, [act, winner]);

  let alwaysActionTag = isAllIn ? BET_TYPE.ALLIN : -1;
  if (isAllIn && rate !== undefined) {
    alwaysActionTag = BET_TYPE.ALLIN
  } else if (blindPos === 'SB') {
    alwaysActionTag = BET_TYPE.SB
  } else if (blindPos === 'BB') {
    alwaysActionTag = BET_TYPE.BB
  }


  let innerIdx = idx;

  if (maxTableMember === 5) {
    if (innerIdx === 2) innerIdx = 3;
    else if (innerIdx === 3) innerIdx = 5;
    else if (innerIdx === 4) innerIdx = 7;
  } else if (maxTableMember === 6) {
    if (innerIdx === 2) innerIdx = 3;
    else if (innerIdx === 3) innerIdx = 4;
    else if (innerIdx === 4) innerIdx = 5;
    else if (innerIdx === 5) innerIdx = 7;
  }

  return <Wrapper
    className={`game-seat seat-${player?.userId}`}
    me={me}
    data-play={isFold || isObserve ? "0" : "1"}
    data-fold={isFold || isObserve || isAfk}
    data-winner={winner}
  >
    {
      (isSeated && player) ? (
        <div className={"player-wrapper"}>
          <div className="top">
            {
              (innerAct) && (
                <ActionTag show={innerShowActionTag} act={innerAct}/>
              )
            }
            {
              (!innerShowActionTag && alwaysActionTag > 0) && <ActionTag show={true} act={alwaysActionTag}/>
            }
            <div
              className={'profile-image-wrapper ' + ((showTimer && betNow) ? 'timer' : '') + ((showTimer && leftSec !== undefined && leftSec < 7) ? ' timer-animation' : '')}>
              <div className="profileImg"
                   onClick={handleClickProfile}
                   data-betnow={betNow}
                   data-winner={winner}
                   style={{backgroundImage: `url(${player.profileImg})`}}
              />
              <div className={'chipleader-wrapper ' + (isChipLeader ? 'show' : '')}>
                <img className='hat' src='/images/chipleader_hat.png'/>
                <img className='light-1' src='/images/chipleader_light_1.png'/>
                <img className='light-2' src='/images/chipleader_light_2.png'/>
                <img className='light-3' src='/images/chipleader_light_3.png'/>
              </div>

              {
                (showTimer) && <TimerProgress percent={
                  leftSec !== undefined ? Math.floor(((leftSec - 2 < 0 ? 0 : leftSec - 2) / (Const.ACTION_LIMIT_TIME - 2)) * 100) : 0
                }/>
              }
              {
                (showTimer) && (
                  <Timer ref={timerRef} className="timer" afk={isAfk}>
                    {
                      isAfk && <img src="/images/ic_afk.svg"/>
                    }
                  </Timer>
                )
              }
            </div>
            <CardDeck idx={innerIdx} className="card-deck" data-seat={player.seat} show={isPlaying || isFold} me={me!}
                      zoom={!me && showCards}>
              <PokerCard tableStyle={tableStyle} className="player-card" card={cards[0]} flip={showCards} delay={100}/>
              <PokerCard tableStyle={tableStyle} className="player-card" card={cards[1]} flip={showCards} delay={100}/>
              {
                (!!handRankingText && me) && (
                  <HandRanking>{handRankingText}</HandRanking>
                )
              }
            </CardDeck>
            {
              winner && <>
                <WinnerLight src="/images/effect/win.png"/>
                {
                  prize > 0 && (
                    <PrizeText>{(Number(prize) > 0 ? '+' : '')}{Number(prize).toLocaleString()}</PrizeText>
                  )
                }
              </>
            }
            {
              (isAllIn || act === BET_TYPE.ALLIN) && (
                <AllInFire src="/images/effect/fire.png"/>
              )
            }
            <StyledWinningRate rate={rate}/>
          </div>
          <div className="bottom">
            <div className="background"/>
            <div className='name'>
              {player.nickname}
            </div>
            <div className="row">
              <div className="stack">
                <ReactFontSizeByTextLength changePerChar={2}>
                 <span>{
                   gameOption.alwaysBB ? `${fixedNumber(player.stackSize / BB, 2)} BB` : player.stackSize.toLocaleString()
                 }</span>
                </ReactFontSizeByTextLength>
              </div>
              <div className="vpip">{Math.floor(player.vpip * 100)}</div>
            </div>
          </div>
        </div>
      ) : (
        <SeatButton bottomPadding={idx} disabled={disabled!} onClick={handleClickSeat}>
          Seat
          <img alt='seat-bg' className='background' src="/images/btn_seat_bg.png"/>
        </SeatButton>
      )
    }
    {
      me && (
        <EmoticonButton onClick={handleClickEmoticon}>
          <img src="/images/Emoji.png"/>
        </EmoticonButton>
      )
    }
    {
      emoticonId && (
        <EmoticonWrapper me={me}>
          <Emoticon id={emoticonId} size={emoticonSize}/>
        </EmoticonWrapper>
      )
    }
  </Wrapper>;
}

export default Player;
