import styled from "styled-components";
import {useCallback, useEffect, useRef} from "react";
import {throttle} from "lodash";
import {playSFX, Sounds} from "../../utils/sound";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
`;

const Gauge = styled.div`
  width: 100%;
  height: 100%;
  background: url(/images/buyin_gauge_h_bg.png) center no-repeat;
  background-size: contain;
  position: relative;
`;

const Fill = styled.div`
  position: absolute;
  top: 0;
  left: 1px;
  width: 0;
  height: 100%;
  will-change: width;
  background-image: url(/images/buyin_gauge_h_fill.svg);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: auto 5px;
  pointer-events: none;
  user-select: none;
`;

const Thumb = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  will-change: left;
  left: 0;
  text-align: center;
  transform: translateX(-25%);

  > .chip {
    position: relative;
    width: 28px;
    height: 28px;
    background-image: url(/images/ic_knob.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    cursor: pointer;
  }
`;

function BuyInGaugeHorizontal(
  {
    min,
    max,
    value,
    onChange
  }: {
    min: number;
    max: number;
    value: number;
    onChange: (value: number) => void;
  }
) {
  const ref = useRef<HTMLImageElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const fillRef = useRef<HTMLDivElement>(null);
  const prevState = useRef<any>(null);
  const innerAmount = useRef(min);
  const playUp = useCallback(throttle(()=>{
    playSFX(Sounds.SFX_BUY_IN_UP);
  },100),[]);
  const playDown = useCallback(throttle(()=>{
    playSFX(Sounds.SFX_BUY_IN_DOWN);
  },100),[]);

  useEffect(() => {
    if (!ref.current || !thumbRef.current) {
      return;
    }

    const gaugeWidth = ref.current.offsetWidth - thumbRef.current.offsetWidth * 0.5;

    const handleMouseEvent = (e: any) => {
      if (!thumbRef.current || !fillRef.current) {
        return;
      }

      e.stopPropagation();

      if (e.type === 'mousedown' || e.type === 'touchstart') {
        const thumbLeft = Number((thumbRef.current.style.left || '0').replace('px', ''));
        if (!prevState.current) {
          const clientX = e.touches ? e.touches[0].clientX : e.clientX;
          prevState.current = {
            thumbLeft: thumbLeft,
            originX: clientX
          };
        }
      } else if (e.type === 'mousemove' || e.type === 'touchmove') {
        if (prevState.current) {
          const clientX = e.touches ? e.touches[0].clientX : e.clientX;
          const dx = clientX - prevState.current.originX;
          const newLeft = Math.max(Math.min(prevState.current.thumbLeft + dx, gaugeWidth), 0);
          const portion = Math.max(Math.min(newLeft / gaugeWidth, 1), 0);
          const amount = Math.floor(min + (max - min) * portion);
          const processedAmount = Math.floor(amount / 5000) * 5000;
          if(processedAmount < innerAmount.current){
            playDown();
          }
          if(processedAmount > innerAmount.current){
            playUp();
          }
          innerAmount.current = processedAmount;

          //5000원 단위로
          onChange && onChange(processedAmount);
          //onChange && onChange(amount);
        }
      } else if (e.type === 'mouseup' || e.type === 'touchend') {
        if (prevState.current) {
          prevState.current = null;
        }
      }
    };

    thumbRef.current.addEventListener('mousedown', handleMouseEvent);
    thumbRef.current.addEventListener('touchstart', handleMouseEvent);
    window.addEventListener('mousemove', handleMouseEvent);
    window.addEventListener('touchmove', handleMouseEvent);
    window.addEventListener('mouseup', handleMouseEvent);
    window.addEventListener('touchend', handleMouseEvent);

    return () => {
      if (thumbRef.current) {
        thumbRef.current.removeEventListener('mousedown', handleMouseEvent);
        thumbRef.current.removeEventListener('touchstart', handleMouseEvent);
        window.removeEventListener('mousemove', handleMouseEvent);
        window.removeEventListener('touchmove', handleMouseEvent);
        window.removeEventListener('mouseup', handleMouseEvent);
        window.removeEventListener('touchend', handleMouseEvent);
      }
    };
  }, [min, max, onChange]);

  useEffect(() => {
    if (!ref.current || !thumbRef.current || !fillRef.current) {
      return;
    }

    const gaugeWidth = ref.current.offsetWidth - thumbRef.current.offsetWidth * 0.5;
    const portion = (value - min) / (max - min);
    const newLeft = gaugeWidth * Math.max(Math.min(portion, 1), 0);
    thumbRef.current.style.left = newLeft + 'px';
    fillRef.current.style.width = newLeft + 'px';
  }, [min, max, value]);

  return <Wrapper>
    <Gauge ref={ref}>
      <Fill ref={fillRef}/>
      <Thumb ref={thumbRef}>
        <div className="chip"/>
      </Thumb>
    </Gauge>
  </Wrapper>;
}

export default BuyInGaugeHorizontal;
