import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Holdem from "../../Holdem";
import ModalContainer from "../../../components/common/ModalContainer";
import LoginModal from "./LoginModal";
import {requestMyInfo, requestPopupNotice, useSocketIsConnect, USER_TOKEN } from "../../../api";
import {setAckListener as updateChatListener} from "../../../api/from_server_ping_updateChat";
import {MyInfo} from "../../../dataset";

const PCMainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;

  > .content {
    padding-top: 60px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .inner {
      width: 100%;
      max-width: 996px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`

const Menu = styled.div<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 6px;
  transition: all .3s;

`

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: var(--Background, #151515);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;

  > .inner {
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1280px;
    height: 100%;

    > .logo-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      > .logo {
        flex-shrink: 0;
        cursor: pointer;
        width: 137.5px;
      }

      > .wrapper {
        flex-grow: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-right: 40px;

        > .menu-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 40px;
          @media (max-width: 1160px) {
            gap: 20px;
          }
        }
      }
    }

    > .button-wrapper {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;

      > .money-point-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        gap: 12px;

        > .money-wrapper {
          height: 32px;
          padding-left: 4px;
          padding-right: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          text-align: right;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;
          > .value {
            color: #fff;
            margin-right: 10px;
          }
          > .money {
            color: #ffdb30;
          }
          > .logout {
            margin-left: 20px;
            background: #fff;
            color: #000;
            padding: 8px;
            cursor: pointer;
          }

          > .point {
            color: #FBD200;
          }

          > .icon {
            width: 24px;
            height: 24px;
          }
        }

        > .money-wrapper.point {
          cursor: pointer;
        }
      }

      > .white-button {
        cursor: pointer;
        display: flex;
        width: 120px;
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 21px;
        border: 1px solid #FFF;
      }

      > .login {
        color: #000;
        text-align: right;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
        background: #fff;
        border-radius: 0;
      }

      > .green-button {
        position: relative;
        cursor: pointer;
        width: 120px;
        height: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 21px;
        background: var(--Point-Mint, #169E7A);
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        > .badge {
          position: absolute;
          top: 5px;
          right: 12px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #E31D1D;
        }
      }
    }
  }
`

const PopupNoticeWrapper = styled.div<{ index: number; hasLink: boolean }>`
  z-index: 9999;
  width: 460px;
  position: fixed;
  ${p => p.index === 0 ? `
  left: 16px;
  ` : ``};
  ${p => p.index === 1 ? `
  right: 16px;
  ` : ``};
  top: 100px;
  background: rgb(85, 75, 50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > .close {
      margin: 4px;
      width: 24px;
      height: 24px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  > .content {
    ${p => p.hasLink ? `cursor: pointer;` : ``};
    width: 100%;
    object-fit: contain;
  }

  > .button {
    margin: 4px;
    cursor: pointer;
    width: 130px;
    padding: 4px;
    border-radius: 15px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    background: rgb(23, 12, 15);

  }

`

const PCMain = () => {
  const [conn] = useSocketIsConnect();
  const params: { type?: string } = useParams();
  const type = params?.type;
  const history = useHistory()
  const [showCustomerCenter, setShowCustomerCenter] = useState<boolean>(false);
  const [myInfo, setMyInfo] = useState<MyInfo | null>(null);
  const [refresh, setRefresh] = useState<number>(new Date().getTime());
  const [showTradePointModal, setShowTradePointModal] = useState<boolean>(false);
  const [unreadChat, setUnreadChat] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [section, setSection] = useState<'home' | 'holdem' | 'trade' | 'mypage' | null>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showFindID, setShowFindID] = useState(false);
  const [showFindPW, setShowFindPW] = useState(false);
  const [popupDataList, setPopupDataList] = useState<any[]>([]);

  useEffect(() => {
    requestPopupNotice().then((res: any) => {
      const key = Object.keys(res).filter(x => x !== 'code')
      const data = key.map(x => res[x])
      setPopupDataList(data)
    })
  }, []);

  useEffect(() => {
    if (
      type && (type === 'home' || type === 'holdem' || type === 'trade' || type === 'mypage')
    ) {
      setSection(type)
    } else {
      setSection('home')
      history.replace(`/m/home`)
    }
    window.scrollTo(0, 0)
    setShowCustomerCenter(false)
  }, [window.location.href])

  useEffect(() => {
    if (!conn.isLogin) return;
    requestMyInfo().then((res) => {
      setMyInfo(res.info);
      setUnreadChat(res.info.unreadChat);
      setUserId(res.info.userId)
    })
  }, [refresh, window.location.href, conn.isLogin]);

  useEffect(() => {
    if (!conn.isLogin) return;
    if (userId) {
      updateChatListener((data: { userId: number; newId: number; }) => {
        setUnreadChat(true);
      });
    }
  }, [userId, conn.isLogin]);

  const handleLogout = (event: any) => {
    event.preventDefault();
    sessionStorage.removeItem(USER_TOKEN);
    conn.isLogin = false
    window.location.href = '/';
  }

  return <PCMainWrapper>
    <ModalContainer align={'top'} show={showLoginModal} onBackdropClick={() => setShowLoginModal(false)}>
      <LoginModal
        onClose={() => setShowLoginModal(false)}
        openSignUp={() => setShowSignUp(true)}
        openFindID={() => setShowFindID(true)}
        openFindPW={() => setShowFindPW(true)}
      />
    </ModalContainer>
    <Header>
      <div className='inner'>
        <div className='button-wrapper'>
          {
            conn.isLogin ? <>
              <div className='money-point-wrapper'>
                <div className='money-wrapper' onClick={() => setRefresh(new Date().getTime())}>
                  <div className='value'>{myInfo && `${myInfo.nickname}`}</div>
                  <div className='money'>Money: {myInfo ? myInfo.money.toLocaleString() : 'Please do login.'}</div>
                  <div className='logout' onClick={handleLogout}>Loggout</div>
                </div>
              </div>
            </> : <>
              <div className='white-button login' onClick={() => setShowLoginModal(true)}>
                Login
              </div>
            </>
          }
        </div>

      </div>
    </Header>
    <div className='content'>
      {
        section === 'holdem' && <div className='inner'>
          <Holdem showLogin={() => {
            setShowLoginModal(true)
          }}/>
        </div>
      }
    </div>
  </PCMainWrapper>
}

export default PCMain;
