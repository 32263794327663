import {useEffect, useRef} from "react";
import {isMobile} from 'react-device-detect'

function useOnResume(handler: () => void, deps: any[]) {
  const hiddenAt = useRef<number>();

  useEffect(() => {
    if (!handler) {
      return;
    }

    function onVisibilityChange(e: any) {
      if (document.visibilityState === 'hidden') {
        hiddenAt.current = Date.now();
      } else if(hiddenAt.current && isMobile) { //모바일이면 바로 갱신
        handler();
        hiddenAt.current = undefined;
      } else if (!isMobile && hiddenAt.current && Date.now() - hiddenAt.current > 60 * 1000) { //PC면 1분 뒤 갱신
        handler();
        hiddenAt.current = undefined;
      }
    }

    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => window.removeEventListener('visibilitychange', onVisibilityChange);
  }, [deps]);
}

export default useOnResume;

