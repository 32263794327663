import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import produce from 'immer';
import moment from "moment";
import styled, {keyframes} from 'styled-components';

import {
  requestBuyin,
  requestGetHandCuttingList,
  requestLeaveRoom,
  requestMyCards,
  requestMyStatusInRoom,
  requestRequestEmoji,
  requestRoomInfo,
  requestSetBlindWait,
  requestTryBet
} from '../api';
import {useHistory} from 'react-router-dom';
import {setAckListener as setStartGameShuffleListener} from "../api/from_server_game_startGameShuffle";
import {setAckListener as setRequestBetListener} from "../api/from_server_game_requestBet";
import {setAckListener as setGameWinnerListener} from "../api/from_server_game_gameWinner";
import {setAckListener as setChangeRoundListener} from '../api/from_server_game_changeRound';
import {setAckListener as setEmitAfterBetListener} from "../api/from_server_game_emitAfterBet";
import {setAckListener as setOpenCommunityCardsListener} from "../api/from_server_game_openCommunityCards";
import {setAckListener as setLeaveJoinRoomSocketListener} from "../api/from_server_game_leaveJoinRoomSocket";
import {setAckListener as setRefreshPlayersInfoListener} from "../api/from_server_game_refreshPlayersInfo";
import {setAckListener as setAllInShowdownListener} from "../api/from_server_game_allInShowdown";
import {setAckListener as setTableCleanUpListener} from "../api/from_server_game_tableCleanUp";
import {setAckListener as setCollectAnteListener} from "../api/from_server_game_collectAnte";
import {setAckListener as setCollectRakeListener} from "../api/from_server_game_collectRake";
import {setAckListener as setSendEmojiListener} from "../api/from_server_game_sendEmoji";
import {
  BET_TYPE,
  CARD_INFO,
  emitAfterBetModel,
  GamePlayer,
  PlayersBettings,
  RAKE_TYPE,
  requestBetModel,
  ROOM_JOIN_STATUS,
  ROOM_STATUS,
  ROOM_TYPE,
  winnerModel
} from '../dataset';
import InGameButton from "../components/common/InGameButton";
import ModalContainer from "../components/common/ModalContainer";
import BuyInModal from "../components/BuyInModal";
import useGameLayout from "../hooks/useGameLayout";
import useLoadGame from "../hooks/useLoadGame";
import Player from "../components/game/Player";
import useDialog from "../hooks/useDialog";
import {numCardsByRank, toCardString, wait} from "../utils/common";
import PokerCard from "../components/game/PokerCard";
import FieldPots from "../components/game/FieldPots";
import PlayerPot from "../components/game/PlayerPot";
import ActionButtons from "../components/game/ActionButtons";
import ProfileModal from "../components/ProfileModal";
import GameHistory from "../components/game/GameHistory";
import RightDrawer from "../components/common/RightDrawer";
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import {parseDatetime} from "../constants/moment";
import {calcBlindUpTime, calcElapsedForRound, calcPlayTime, determineRestTime} from "../utils/tournament";
import SettingModal from "../components/SettingModal";
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {myInfoState} from "../recoil/MyInfo";
import Flex from "../components/common/Flex";
import StatusBoard from "../components/game/StatusBoard";
import useWinningRate from "../hooks/useWinningRate";
import {globalLoadingState} from "../recoil/Loading";
import {connectionState} from "../recoil/Connection";
import {playSFX, Sounds, stopBGM, stopSound} from "../utils/sound";
import useOnResume from "../hooks/useOnResume";
import LeaveSettlement from "../components/game/LeaveSettlement";
import EmoticonSelector from "../components/game/EmoticonSelector";
import {
  betLeftSecState,
  ceremonyCardsState,
  ceremonyRankingState,
  communityCardsState,
  emoticonState,
  myCardsState,
  shiningCardsState
} from "../recoil/Game";

// @ts-ignore
import {Hand} from "pokersolver";
import {useTranslation} from "react-i18next";
import {moveChipsSound} from "../utils/chip";
import ParticleBackground from "../components/game/ParticleBackground";
import {gameOptionState} from '../recoil/GameOption';
import HandDescModal from "../components/game/HandDescModal";

const EmoticonHotKeyWrapper = styled.div<{show: boolean}>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${p => p.show ? `1`: `0`};
  pointer-events: ${p => p.show ? `auto`: `none`};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  display: flex;
  flex-direction: row;
  gap: 19px;
  z-index: 8;
  align-items: center;
  justify-content: center;
  >.item {
    cursor: pointer;
    width: 36px;
    height: 36px;
    object-fit: contain;
    @media ${MEDIA_DESKTOP} {
      width: 56px;
      height: 56px;    
    }
  }
`
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  z-index: 1;
  gap: 8px;

  @media ${MEDIA_DESKTOP} {
    padding: 30px 40px;
  }

  #roomInfo {
    > div {
      margin-left: 16px;
      margin-top: 0;
      position: initial;
      top: 0;
      left: 0;
    }
  }
`;


const LeaveButton = styled.button`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #7E7E7E;
  background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  > img {
    width: 24px;
    height: 24px;
  }
  @media ${MEDIA_DESKTOP} {
    width: 70px;
    height: 56px;
    border-radius: 16px;
    border: 2px solid #7E7E7E;
    background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
    >img {
      width: 48px;
      height: 48px;
    }
  }
`;
const HandRankingHelpButton = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -82px;
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  object-fit: contain;
  opacity: 1 !important;
  pointer-events: visible;
  @media ${MEDIA_DESKTOP} {
    position: static;
    bottom: unset;
    left: unset;
    width: 56px !important;
    height: 56px !important;
    transform: none;
  }
`
const HistoryButton = styled.button`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #7E7E7E;
  background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  > img {
    width: 24px;
    height: 24px;
  }
  @media ${MEDIA_DESKTOP} {
    width: 56px;
    height: 56px;
    border-radius: 16px;
    border: 2px solid #7E7E7E;
    background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
    >img {
      width: 48px;
      height: 48px;
    }
  }
`;


const OptionButton = styled.button`
  color: white;
  width: 28px;
  height: 28px;

  font-size: 10px;
  padding: 8px 13px;
  border-radius: 15px;
  background: #181A1D;
  mix-blend-mode: darken;
  cursor: pointer;
  position: relative;

  @media ${MEDIA_DESKTOP} {
    width: 36px;
    height: 36px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const RoomName = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 10px;
`;

const FlopCardStartAnimation = keyframes`
  from {
    transform: scale(2);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const FlopCardOpenAnimation = keyframes`
  to {
    transform: translateX(0);
  }
`;

const CommunityCards = styled.div<{
  noAnimation: boolean
}>`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
  position: absolute;
  z-index: 6;
  align-items: flex-end;
  pointer-events: none;

  &:not([data-cards="0"]) {
    animation: ${FlopCardStartAnimation} 0.2s linear;
    ${p => p.noAnimation && `
      animation-duration: 0s !important;
    `};
  }

  > * {
    width: 42px;
    height: 56px;
    opacity: 0;
    @media ${MEDIA_DESKTOP} {
      width: 95px;
      height: 133px;
    }
    &[data-open="true"] {
      opacity: 1;
      animation-delay: 0.4s;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;

      ${p => p.noAnimation && `
        animation-duration: 0s !important;
        animation-delay: 0s !important;
      `};

      &:nth-of-type(1) {

      }

      &:nth-of-type(2) {
        transform: translateX(calc(-100% - 2px));
        animation-name: ${FlopCardOpenAnimation};
      }

      &:nth-of-type(3) {
        transform: translateX(calc(-200% - 4px));
        animation-name: ${FlopCardOpenAnimation};
      }

      &:nth-of-type(4) {
        animation: ${FlopCardStartAnimation} 0.2s linear 0s;
      }

      &:nth-of-type(5) {
        animation: ${FlopCardStartAnimation} 0.2s linear 0s;
      }
    }
  }
`;

const WinnerHandRanking = styled.div`
  width: auto;
  height: auto;
  min-width: 158px;
  opacity: 1;
  position: absolute;
  bottom: -11px;
  transform: translateY(100%);
  padding: 4px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%) 1;
  background: linear-gradient(90deg, rgba(24, 24, 24, 0.00) -2.1%, #181818 19.17%, #181818 84.58%, rgba(24, 24, 24, 0.00) 100%);

  @media ${MEDIA_DESKTOP} {
    height: 44px;
    min-width: 515px;
  }

  > div {
    backdrop-filter: drop-shadow(0 2px 4px #000);
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(180deg, #FFF 0%, #FFC92C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media ${MEDIA_DESKTOP} {
      font-size: 30px;
    }
  }
`;

const DealerButton = styled.div`
  width: 15px;
  height: 15px;
  background-image: url(/images/ic_chip_dealer.png);
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  @media ${MEDIA_DESKTOP} {
    width: 30px;
    height: 30px;
  }
`;

const CoinMoveWrapper = styled.div`
  .move-coin {
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    background-size: contain;
    opacity: 1;
    z-index: 1;

    * {
      animation: none !important;
    }
  }
`;

const CeremonyDimmer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
`;

const GameTable = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  padding-top: 50px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  >.video-background {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: block;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: 100%;
    height: 100%;
    > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > img.game-table {
    width: 125%;
    height: 132%;
    object-fit: contain;
    aspect-ratio: 904/1408;
    @media ${MEDIA_DESKTOP} {
      width: 105%;
      height: 105%;
      aspect-ratio: 1626/950;
    }
    pointer-events: none;
    user-select: none;
  }

  > div.table-hole {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
  }
`;

const JoinButtonWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;

  > div {
    width: auto;
  }

  @media ${MEDIA_DESKTOP} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 20px;
    padding: 4px 8px;
  }
`;

type WinnerCards = {
  [userId: number]: number[]
}
type PlayerAct = {
  [userId: number]: number
}

const RestTimePopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 480px;
  background: linear-gradient(90deg, rgba(24, 26, 29, 0.00) 0%, rgba(24, 26, 29, 0.50) 17.71%, rgba(24, 26, 29, 0.50) 82.32%, rgba(24, 26, 29, 0.00) 100%);
  padding: 8px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  z-index: 99;
`;

const InGameButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  padding: 20px 24px;
  @media ${MEDIA_DESKTOP} {
    min-width: 860px;
    width: 50%;
  }
`;


const WaitButtonWrapper = styled.div<{show: boolean}>`
  opacity: ${p => p.show ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  //show가 아닐때 클릭 막기
  pointer-events: ${p => p.show ? 'visible' : 'none'};
  position: fixed;
  display: flex;
  flex-direction: row;
  padding: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  bottom: 30px;
  left: 0;
  gap: 6px;
  z-index: 4;

  @media ${MEDIA_DESKTOP} {
    max-width: 860px;
    padding: 0;
    justify-content: flex-end;
    left: unset;
    right: 60px;
    bottom: 60px;
    gap: 14px;
  }
`;


// eslint-disable-next-line import/no-anonymous-default-export
export default (): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const {openDialog} = useDialog();
  const {
    roomId,
    room,
    players,
    myInfo,
    blind,
    gameStyle,
    isWait,
    updatePlayer,
    updateStackSize,
    maxTableMember
  } = useLoadGame();

  // 대기 BGM 재생
  //useBGM(Sounds.BGM_WAIT, isWait);

  const [setting, setSetting] = useRecoilState(gameOptionState);
  const myProfileInfo = useRecoilValue(myInfoState);
  const connected = useRecoilValue(connectionState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const setEmoticon = useSetRecoilState(emoticonState);
  const setShiningCards = useSetRecoilState(shiningCardsState);
  const setCeremonyCards = useSetRecoilState(ceremonyCardsState);
  const [myCards, setMyCards] = useRecoilState(myCardsState);
  const [betLeftSec, setBetLeftSec] = useRecoilState(betLeftSecState);
  const [communityCards, setCommunityCards] = useRecoilState(communityCardsState);
  const [ceremonyRanking, setCeremonyRanking] = useRecoilState(ceremonyRankingState);
  const [showChipLeader, setShowChipLeader] = useState<boolean>(false);
  const initialized = useRef<boolean>(false);
  const isPlaying = useRef<boolean>(false);
  const [noRoundAnim, setNoRoundAnim] = useState<boolean>(true);
  const [betData, setBetData] = useState<requestBetModel | null>(null);
  const [winners, setWinners] = useState<winnerModel[]>([]);
  const [winnerCards, setWinnerCards] = useState<WinnerCards>({});
  const [pots, setPots] = useState<number[]>([]);
  const [buyInSeat, setBuyInSeat] = useState<number>(-1);
  const [sentAct, setSentAct] = useState<boolean>(false);
  const [playersBetting, setPlayersBetting] = useState<PlayersBettings[]>([]);
  const [playersAct, setPlayersAct] = useState<PlayerAct>({});
  const [timerText, setTimerText] = useState<string>('');
  const [blindTimerText, setBlindTimerText] = useState<string>('');
  const [isRestTime, setRestTime] = useState<boolean>(false);
  const [myRank, setMyRank] = useState<number>(0);
  const [totalMember, setTotalMember] = useState<number>(0);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [showHandDesc, setShowHandDesc] = useState<boolean>(false)
  const [profileUserId, setProfileUserId] = useState<number>(-1);
  const [showOptionModal, setShowOptionModal] = useState<boolean>(false);
  const orientation = useScreenOrientation();
  const [autoCheckFold, setAutoCheckFold] = useState<boolean>(false);
  const [autoCall, setAutoCall] = useState<boolean>(false);
  const [autoCallAny, setAutoCallAny] = useState<boolean>(false);
  const [autoAction, setAutoAction] = useState<'check/fold' | 'autocall' | null>(null);
  const [lastBet, setLastBet] = useState<number>(0);
  const [showAutoAction, setShowAutoAction] = useState<boolean>(false);
  const [showAutoActionForRequest, setShowAutoActionForRequest] = useState<boolean>(false);
  const [showEmoticonSelector, setShowEmoticonSelector] = useState<boolean>(false);
  const [showEmotionHotKey, setShowEmotionHotKey] = useState<boolean>(false);
  const showingWinner = useRef<boolean>(false);
  const [innerCommunityCards, setInnerCommunityCards] = useState<number[]>([]);
  const [startRemoveCommunityCards, setStartRemoveCommunityCards] = useState<boolean>(false);
  const [innerLegalActs, setInnerLegalActs] = useState<string[]>([]);
  const [chipLeader, setChipLeader] = useState<GamePlayer | null>(null);
  const [tableStyle, setTableStyle] = useState<number>(-1);

  useEffect(() => {
    if(blind.big > 0){
      if(blind.big <= 1000){
        setTableStyle(setting.tableStyle_LV1 ?? 1)
      }else if(blind.big <= 10000){
        setTableStyle(setting.tableStyle_LV2 ?? 2)
      }else{
        setTableStyle(setting.tableStyle_LV3 ?? 3)
      }
    }
  }, [blind.big, setting]);

  useEffect(() => {
    if(innerCommunityCards.length > 0 && communityCards.length === 0){
      //카드 사라지게 하는 애니

      setStartRemoveCommunityCards(true);
      setTimeout(()=>{
        setStartRemoveCommunityCards(false);
        setInnerCommunityCards(communityCards);
      }, 2000)
    }else{
      setInnerCommunityCards(communityCards);
    }
  }, [communityCards, innerCommunityCards]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (room) {
        if (room.type === ROOM_TYPE.TOURNAMENT) {
          // 휴식시간 세팅
          const startedAt = parseDatetime(room.groupData.startedAt);
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
            if (room?.currentRound === null || room?.currentRound === undefined) {
              setRestTime(true);
            } else {
              setRestTime(false);
            }
          }
        }
      }
    }, 1000)
    return () => {
      clearInterval(interval);
    }
  }, [room])

  const gameLayout = useGameLayout({
    dealerIndex: players.findIndex(p => p?.seat === room?.buttonPosition),
    maxTableMember: maxTableMember,
    pots: pots,
  });

  const calculator = useWinningRate();

  const showActions = useMemo<boolean>(() => {
    if(myInfo?.waitGame){
      return false;
    }else if (sentAct) {
      return false;
    } else if (betData?.userId !== myInfo?.userId) {
      return false;
    } else if (!betData?.legalAct) {
      return false;
    }

    return true;
  }, [betData?.userId, myInfo?.userId, sentAct, myInfo?.waitGame]);

  const showJoinButtonRing = useMemo<boolean>(() => {
    return room?.type === ROOM_TYPE.RING && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
  }, [room, myInfo?.status, myInfo?.waitGame]);

  const showJoinButtonTournament = useMemo<boolean>(() => {
    return room?.type === ROOM_TYPE.TOURNAMENT && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
  }, [room, myInfo?.status, myInfo?.waitGame]);

  const isRebuyInAvailable = useMemo<boolean>(() => {
    if (myInfo?.blindWait === true) {
      if (room?.type === ROOM_TYPE.RING) {
        const rake = room?.groupData?.rake;
        const rakeType = room?.groupData?.rakeType;

        if (rakeType === RAKE_TYPE.HAND_RAKE) {
          if (myInfo?.stackSize < rake + blind.big + blind.ante) {
            return true;
          }
        } else if (rakeType === RAKE_TYPE.POT_RAKE) {
          if (myInfo?.stackSize < blind.big + blind.ante) {
            return true;
          }
        }
      } else {
        if (myInfo?.stackSize > 0) {
          return true;
        }
      }
    }

    return false;
  }, [room?.groupData, myInfo?.blindWait, myInfo?.stackSize, blind]);

  const totalPotSize = useMemo<number>(() => {
    const totalBet = playersBetting.reduce((a, v) => a + v.bet, 0);
    const collectedPotSize = pots.reduce((a, v) => a + v, 0);

    return totalBet + collectedPotSize;
  }, [playersBetting, pots]);

  const resetGame = useCallback(() => {
    setMyCards([]);
    setCommunityCards([]);
    setBetData(null);
    setWinners([]);
    setWinnerCards([]);
    setPots([]);
    setPlayersBetting([]);
    setSentAct(false);
    setPlayersAct({});
    setShiningCards([]);
    setCeremonyCards([]);
    setCeremonyRanking('');
    calculator.resetWinningRates();
    // 카드 요소 전부 감추기
    gameLayout.hideAllCards();
  }, []);

  const refreshGame = useCallback(() => {
    if (roomId >= 0) {
      (async () => {
        initialized.current = false;
        setGlobalLoading(true);
        await requestRoomInfo(roomId);
        await requestMyStatusInRoom(roomId);
        requestMyCards(roomId).then(({cards}) => {
          if (cards) {
            setMyCards(cards);
          }
        });
        setGlobalLoading(false);
      })();
    }
  }, [roomId]);

  useEffect(() => {
    stopBGM();
  }, []);
  useEffect(() => {
    resetGame();
  }, [roomId]);

  useEffect(() => {
    if (connected) {
      refreshGame();
    }
  }, [connected, refreshGame]);

  useOnResume(() => {
    resetGame();
    refreshGame();
  }, [refreshGame]);

  // 진행중인 게임 데이터 구성
  useEffect(() => {
    if (!room || initialized.current) {
      return;
    }

    const acts: PlayerAct = {};
    const betting: PlayersBettings[] = [];
    for (let player of room.players) {
      const isFolded = player.status === ROOM_JOIN_STATUS.FOLD
      acts[player.userId] = isFolded ? BET_TYPE.FOLD : player.lastAction;
      betting.push({
        id: player.userId,
        bet: player.bet,
        ante: player.ante,
        rake: player.rake,
        stackSize: player.stackSize,
        folded: isFolded,
        prevStackSize: player.prevStackSize,
      });
    }
    setPlayersAct(acts);
    setPlayersBetting(betting);
    setCommunityCards(room.cards || []);

    const pots = room.pots || [];
    // ante가 남아있는 경우 팟에 포함시키기
    const totalAnte = betting.reduce((a, v) => a + v.ante, 0);
    if (totalAnte > 0 && pots.length === 1 && pots[0] === 0) {
      pots[0] = pots[0] + totalAnte
    }
    setPots(pots);

    if (room.roomStatus === ROOM_STATUS.INGAME) {
      isPlaying.current = true;
      gameLayout.showAllCards();
    }

    if (room.type === ROOM_TYPE.TOURNAMENT) {
      // 휴식시간 세팅
      const startedAt = parseDatetime(room.groupData.startedAt);
      const {
        playTimeSeconds,
        restTimeSeconds
      } = room.groupData.timeStructure;
      if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
        if (room?.currentRound === null || room?.currentRound === undefined) {
          if (!showingWinner.current) {
            setRestTime(true);
            gameLayout.hideAllCards();
          }
        } else {
          setRestTime(false);
        }

      }
    }

    initialized.current = true;
  }, [room]);

  const playFlipSound = async () => {
    const soundArr = [Sounds.SFX_CARD_DIS,Sounds.SFX_CARD_DIS,Sounds.SFX_CARD_DIS,Sounds.SFX_FLOP]
    for(let i = 0; i < 4; i++){
      await wait(300)
      playSFX(soundArr[i])
    }
  }

  // 커뮤니티 카드 플립 사운드 재생
  useEffect(() => {
    if(communityCards.length === 3){
      playFlipSound();
    }else if (communityCards.length > 3) {
      playSFX(Sounds.SFX_RIVER_CARD_OPEN);
    }
  }, [communityCards]);

  // 타이머 사운드 재생
  useEffect(() => {
    if (betLeftSec && betData?.userId === myInfo?.userId) {
      if (betLeftSec <= 4) {
        playSFX(Sounds.SFX_TIMER_ALERT);
      }
    }
  }, [betData, myInfo?.userId, betLeftSec])

  // 내 차례 사운드 재생, 내 차레 끝나면 타이머 사운드 중지
  useEffect(() => {
    if (betData && myInfo && betData?.userId === myInfo?.userId) {
      //playSFX(Sounds.SFX_MYTURN);
    } else {
      stopSound(Sounds.SFX_TIMER_ALERT);
    }
  }, [betData?.userId, myInfo?.userId]);

  // 메이드, 프리미엄 카드 반짝임 효과
  useEffect(() => {
    if (myCards.length !== 2) {
      return;
    }

    if (communityCards.length === 0) {
      // 프리미엄 카드 효과
      // AA, KK, QQ, JJ, AK, 1010, Aqs, Ajs, KQs
      const cards = [CARD_INFO[myCards[0]], CARD_INFO[myCards[1]]]
      const [valueL, suitL] = cards[0].split('');
      const [valueR, suitR] = cards[1].split('');
      const values = [valueL, valueR].sort().join('');

      if (values === 'AA' || values === 'KK' || values === 'QQ' || values === 'JJ' || values === 'TT' || values === 'AK') {
        setShiningCards(cards);
      } else if ((values === 'AQ' || values === 'AJ' || values === 'KQ') && suitL === suitR) {
        setShiningCards(cards);
      }
    } else {
      // 메이드 효과
      const solved = Hand.solve([
        ...myCards.map(x => CARD_INFO[x]),
        ...communityCards.map(x => CARD_INFO[x])
      ]);
      if (solved.rank >= 4) {
        const numCards = numCardsByRank(solved.rank)
        const targetCards = solved.cards.map((x: any) => toCardString(x.value, x.suit)).slice(0, numCards);
        setShiningCards(targetCards);
      }
    }
  }, [myCards, communityCards]);

  useEffect(() => {
    setStartGameShuffleListener(async (data: {
      roomId: number,
      userId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      if (isRestTime) {
        setRestTime(false);
      }
      showingWinner.current = false;
      setMyCards([]);
      isPlaying.current = true;

      const chipLeader = players.filter(x => {
        const disabled = !x && !!myInfo && myInfo?.status !== ROOM_JOIN_STATUS.OBSERVE;
        return !disabled
      }).sort((a, b) => (b?.stackSize ?? 0)  - (a?.stackSize ?? 0))[0];
      setChipLeader(chipLeader ?? null);

      setShowChipLeader(true);
      // 모든 카드 가운데로 모으기
      gameLayout.resetPlayersCards();

      // 딜러버튼 위치부터 시계방향으로 배열 만들어서 카드 딜링
      let arr = players.filter(x => x !== undefined) as GamePlayer[];
      const startIndex = arr.findIndex(x => x?.userId === data?.userId);
      arr = arr.splice(startIndex).concat(arr);
      await gameLayout.dealCardsToPlayers(arr);

      // SB/BB
      const playersBetting: PlayersBettings[] = [];
      for (let player of players) {
        if (player && player.status === ROOM_JOIN_STATUS.PLAYING && player.bet > 0) {
          playSFX(moveChipsSound(player.bet));
          playersBetting.push({
            id: player.userId,
            bet: player.bet,
            ante: player.ante,
            rake: player.rake,
            folded: false,
            stackSize: player.stackSize,
            prevStackSize: player.prevStackSize,
          });
          setPlayersBetting(playersBetting);
        }
      }

      // 내 카드 세팅
      if (myInfo && myInfo.seat >= 0) {
        const {cards} = await requestMyCards(roomId);
        setMyCards(cards);
      }
    });
    setRequestBetListener(async (data: requestBetModel) => {
      if (roomId != data.roomId) {
        return;
      }
      if (!isPlaying.current) {
        isPlaying.current = true;
      }
      setShowChipLeader(true);
      setShowAutoAction(true)
      setShowAutoActionForRequest(true)
      if (!betData || data.userId !== betData.userId) {
        setBetData(data);
      }

      if (data.leftSec === null || data.leftSec === undefined) {
        setBetLeftSec(undefined);
      } else {
        setBetLeftSec(data.leftSec);
      }

      if (data.userId === myInfo?.userId) {
        if (autoCall || autoCallAny) {
          if (data.legalAct.includes('call')) {
            await handleTryBet(BET_TYPE.CALL, 0)

          } else if (data.legalAct.includes('check')) {
            await handleTryBet(BET_TYPE.CHECK, 0)
          }
        } else {
          if (autoCheckFold) {
            if (data.legalAct.includes('check')) {
              await handleTryBet(BET_TYPE.CHECK, 0)
            } else {
              await handleTryBet(BET_TYPE.FOLD, 0)
            }
          }
        }
        setAutoCall(false)
        setAutoCallAny(false)
        setAutoCheckFold(false)
      }
      if (sentAct && data.userId !== myInfo?.userId) {
        setSentAct(false);
      }
    });
    setEmitAfterBetListener(async (data: {
      model: emitAfterBetModel,
      roomId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      const model = data.model;

      if (model.lastBetStatus) {
        const {
          userId,
          type,
          bet
        } = model.lastBetStatus;

        if (type === BET_TYPE.CHECK) {
          const checkSound = [Sounds.SFX_CHECK_1, Sounds.SFX_CHECK_2]
          playSFX(checkSound[Math.floor(Math.random() * checkSound.length)], true)
        } else if (type === BET_TYPE.CALL) {
          const soundList = [Sounds.SFX_BET_1,Sounds.SFX_BET_2,Sounds.SFX_BET_3,Sounds.SFX_BET_4]
          playSFX(soundList[Math.floor(Math.random() * soundList.length)])
        } else if (type === BET_TYPE.RAISE || type === BET_TYPE.BET) {
          const soundList = [Sounds.SFX_BET_1,Sounds.SFX_BET_2,Sounds.SFX_BET_3,Sounds.SFX_BET_4]
          playSFX(soundList[Math.floor(Math.random() * soundList.length)])
        } else if (type === BET_TYPE.ALLIN) {
          playSFX(Sounds.SFX_ALLIN, true)
        } else if (type === BET_TYPE.FOLD) {
          playSFX(Sounds.SFX_FOLD, true)
        }

        setPlayersAct(produce((d) => {
          d[userId] = type;
        }));

        if (bet > 0) {
          playSFX(moveChipsSound(bet));
        }

        setBetData(null);
      }
      if (model.playerBettings) {
        setPlayersBetting(model.playerBettings);

        // 스택사이즈 업데이트
        for(let {id, stackSize} of model.playerBettings) {
          updateStackSize(id, stackSize);
        }
      }
      // 올인인 경우 팟 반영
      if (model.isAllIn && model.pots) {
        setPots(model.pots.map(x => x.amount + x.potRake));
        gameLayout.moveCoinToGamePot();
        setPlayersBetting([]);
      }
    });
    setChangeRoundListener(async (data: {
      roomId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      setShowChipLeader(true);
      if (room) {
        if (room.currentRound !== null && room.currentRound !== undefined) {
          showingWinner.current = false;
        }
        // changeRound 될때 자동체크폴드 콜  초기화
        setAutoCall(false)
        setAutoCallAny(false)
        setAutoCheckFold(false)

        setShowAutoAction(false);
        setNoRoundAnim(false);

        const prevTotalPot = pots.reduce((a, v) => a + v, 0);
        const newTotalPot = room.pots.reduce((a, v) => a + v, 0);
        if (newTotalPot > prevTotalPot) {
          gameLayout.moveCoinToGamePot();
          setPots(room.pots || []);
        }
        setPlayersBetting([]);

        // 폴드 외 액션 삭제
        setPlayersAct(produce((d) => {
          for (let userId in d) {
            if (d[userId] !== BET_TYPE.FOLD) {
              delete d[userId];
            }
          }
        }));
        await wait(500);
        setCommunityCards(room.cards || []);
      }
    });
    setAllInShowdownListener(async (data: {
      roomId: number,
      wholeCards: {
        userId: number,
        holeCards: number[]
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      setShowChipLeader(true);
      // 승률 설정 전 리셋
      calculator.resetWinningRates();
      const innerWinnerCards: WinnerCards = {};
      for (let {
        userId,
        holeCards
      } of data.wholeCards) {
        innerWinnerCards[userId] = holeCards;
        calculator.addPlayer(userId, holeCards);
      }
      if (innerWinnerCards) {
        setWinnerCards(prev => {
          return {
            ...prev,
            ...innerWinnerCards
          }
        })
      }
    });
    setOpenCommunityCardsListener(async (data: {
      roomId: number;
      cards: number[];
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      setShowChipLeader(true);
      setCommunityCards(data.cards);
      await calculator.setCommunityCard(data.cards);

      setTimeout(() => {
        calculator.updateWinningRate();
      }, 1000);
    });
    setGameWinnerListener(async (data: {
      roomId: number;
      winners: winnerModel[];
      players: GamePlayer[];
      prize: number;
      wholeCards: {
        [key: number]: number[]
      };
      tournamentRankingList: {
        userId: number,
        stackSize: number,
        ranking: number
      }[];
      totalMemberCount: number;
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (room) {
        setShowChipLeader(true);
        const chipLeader = players.filter(x => {
          const disabled = !x && !!myInfo && myInfo?.status !== ROOM_JOIN_STATUS.OBSERVE;
          return !disabled
        }).sort((a, b) => (b?.stackSize ?? 0)  - (a?.stackSize ?? 0))[0];
        setChipLeader(chipLeader ?? null);
        // 위너 설정
        setWinnerCards(data.wholeCards || {});
        await wait(1000);

        setWinners(data.winners);
        const allPlayerCount = data.players.filter(x => [ROOM_JOIN_STATUS.PLAYING, ROOM_JOIN_STATUS.FOLD].includes(x.status)).length;
        const foldPlayerCount = data.players.filter(x => x.status === ROOM_JOIN_STATUS.FOLD).length;
        //내가 이겼을때 사운드
        if(data.winners.find(x => x.userId === myInfo?.userId)){
          //상대방이 다 폴드해서 이긴경우
          //즉 폴드플레이어가 전체 -1 인 경우
          if(allPlayerCount - 1 === foldPlayerCount){
            playSFX(Sounds.SFX_WIN_FOLD)
          }else{
            playSFX(Sounds.SFX_OPEN_WIN)
          }
        }else{ //내가 졌을때 사운드
          //내가 폴드해서 진경우
          if(allPlayerCount - 1 === foldPlayerCount){
            playSFX(Sounds.SFX_FOLD_LOSE)
          }else{
            playSFX(Sounds.SFX_OPEN_LOSE)
          }
        }
        if (data.wholeCards) {
          setWinnerCards(prev => {
            return {
              ...prev,
              ...data.wholeCards
            }
          })
          playSFX(Sounds.SFX_SHOW_HIDE_CARDS);
        }
        calculator.resetWinningRates();
        await gameLayout.moveCoinToWinners(data.winners);

        // 스택 업데이트
        for(let {userId, stackSize} of data.players) {
          updateStackSize(userId, stackSize);
        }

        setPots([]);
        setTimeout(()=>{
          setShowEmotionHotKey(true);
          setTimeout(()=>{
            setShowEmotionHotKey(false);
          }, 2000)
        }, 1000)
        setTimeout(() => {
          showingWinner.current = false;
        }, 2000)
        // 토너먼트 처리
        if (room.type === ROOM_TYPE.TOURNAMENT) {
          // 랭킹 세팅
          if (data.tournamentRankingList) {
            const myRanking = data.tournamentRankingList.find(x => x.userId === myInfo?.userId);
            if (myRanking) {
              setMyRank(myRanking.ranking);
              setTotalMember(data.totalMemberCount);
            }
          }

          // 휴식시간 세팅
          const startedAt = parseDatetime(room.groupData.startedAt);
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
            if (room?.currentRound === null || room?.currentRound === undefined) {
              setRestTime(true);
            } else {
              setRestTime(false);
            }
          }
        }
      }
    });
    setTableCleanUpListener(async () => {
      resetGame();
      isPlaying.current = false;
    });
    setLeaveJoinRoomSocketListener(async (data: any) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.reason == -1) {
        playSFX(Sounds.SFX_WINDOW_OPEN);
        openDialog({
          title: t('Info'),
          text: t('The game room was closed and moved to the waiting room.'),
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      } else if (data.reason == -2) {
        playSFX(Sounds.SFX_WINDOW_OPEN);
        openDialog({
          title: t('Info'),
          text: t('Since there was no input for a long time, I was moved to the waiting room.'),
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      } else if (data.reason == -4) {
        playSFX(Sounds.SFX_TOURNAMENT_LOSE);
        openDialog({
          title: t('Info'),
          text: t('I lost all my chips and was taken to the waiting room.'),
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      } else if (data.reason == -5) {
        playSFX(Sounds.SFX_WINDOW_OPEN);
        openDialog({
          title: t('Info'),
          text: t('The settlement has been completed and you will be sent out.'),
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      }

      history.push('/');
    });
    setRefreshPlayersInfoListener(async (data: {
      roomId: number,
      players: GamePlayer[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      const joinedUser = Object.values(data.players).find(x => x !== null);
      if (!joinedUser) {
        playSFX(Sounds.SFX_GAME_OUT);
      } else if (joinedUser.blindWait) {
        playSFX(Sounds.SFX_GAME_IN);
      }

      for (let seat in data.players) {
        const player = data.players[seat];

        if (player) {
          if (player.status === ROOM_JOIN_STATUS.FOLD) {
            // 유저가 나갔다가 다시 들어와서 FOLD 처리
            setPlayersAct(produce(d => {
              d[player.userId] = BET_TYPE.FOLD;
            }))
          }
          // else if (isPlaying.current) {
          //   player.status = ROOM_JOIN_STATUS.BUYIN_READY;
          // }
        }


        await updatePlayer(Number(seat), player);
      }

      if (isPlaying.current && (room?.type !== ROOM_TYPE.TOURNAMENT || !isRestTime)) {
        gameLayout.showAllCards();
      }
    });
    setCollectAnteListener(async (data: {
      roomId: number,
      antePlayers: {
        userId: number,
        ante: number
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.antePlayers) {
        for (let {
          userId,
          ante
        } of data.antePlayers) {
          setPots(produce(d => {
            d[0] = (d[0] || 0) + ante;
          }));
          await gameLayout.moveCoinAnte(userId, ante);
        }
      }
    });
    setCollectRakeListener(async (data: {
      roomId: number,
      rakePlayers: {
        userId: number,
        rake: number
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.rakePlayers) {
        for (let {
          userId,
          rake
        } of data.rakePlayers) {
          await gameLayout.moveCoinRake(userId, rake);
        }
      }
    });
    setSendEmojiListener(async (data: {
      roomId: number,
      userId: number,
      emojiId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      const {
        emojiId,
        userId
      } = data
      setEmoticon(produce(d => {
        d[userId] = emojiId;
      }));

      // 3초 뒤 해당 플레이어의 이모티콘 삭제
      setTimeout(() => {
        setEmoticon(produce(d => {
          if (d[userId] === emojiId) {
            delete d[userId];
          }
        }));
      }, 3000);
    });
    return () => {
      setStartGameShuffleListener(null);
      setRequestBetListener(null);
      setEmitAfterBetListener(null);
      setChangeRoundListener(null);
      setAllInShowdownListener(null);
      setOpenCommunityCardsListener(null);
      setGameWinnerListener(null);
      setTableCleanUpListener(null);
      setLeaveJoinRoomSocketListener(null);
      setRefreshPlayersInfoListener(null);
      setCollectAnteListener(null);
      setCollectRakeListener(null);
      setSendEmojiListener(null);
    };
  });
  //자동 콜 풀리는 로직
  useEffect(() => {
    if (room?.currentBet) {
      if (lastBet !== room?.currentBet) {
        setAutoCall(false)
        setLastBet(0)
      }
    } else {
      setAutoCall(false)
      setLastBet(0)
    }
  }, [room?.currentBet])


  useEffect(() => {
    if(betData?.userId === myInfo?.userId){
      setTimeout(()=>{
        setInnerLegalActs(betData?.legalAct ?? [])
      }, 100)
    }
  }, [betData, myInfo]);


  // 토너먼트 남은시간 타이머 계산
  useEffect(() => {
    if (!room || room.type !== ROOM_TYPE.TOURNAMENT) {
      return;
    }

    const startedAt = parseDatetime(room.groupData.startedAt);
    const {
      playTimeSeconds,
      restTimeSeconds
    } = room.groupData.timeStructure;

    const interval = setInterval(() => {
      let leftSec = 0;

      const elapsedForRound = calcElapsedForRound(startedAt, playTimeSeconds, restTimeSeconds);
      const playTime = calcPlayTime(parseDatetime(room.groupData.startedAt), playTimeSeconds, restTimeSeconds);
      const blindUptime = calcBlindUpTime(playTime, room.groupData.blindStructure);
      if (blindUptime) {
        setBlindTimerText(moment.unix(blindUptime).format(t('blind up : mmmins sssecs 남음')));
      }
      if (elapsedForRound > 0) {
        if (elapsedForRound >= playTimeSeconds) {
          // 현재 휴식 시간. 다음 라운드까지 남은 시간
          if (isRestTime) {
            leftSec = playTimeSeconds + restTimeSeconds - elapsedForRound;
          }
        } else {
          // 현재 라운드 진행 중. 다음 휴식 시간까지 남은 시간
          if (!isRestTime) {
            leftSec = playTimeSeconds - elapsedForRound;
          }
        }
      } else {
        // 토너먼트 시작 전. 시작까지 남은 시간
        leftSec = -elapsedForRound;
      }

      if (leftSec >= 0) {
        setTimerText(moment.unix(leftSec).format(t('mmmins sssecs left')));
      }
      if (leftSec <= 0) {
        setRestTime(false)
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [room, isRestTime]);

  useEffect(() => {
    if(!isRebuyInAvailable && myInfo &&  myInfo?.stackSize > 0 && myInfo?.stackSize < blind.big && !myInfo.blindWait && winners.length > 0){

      requestSetBlindWait(roomId, true)
    }
  }, [myInfo?.stackSize, isRebuyInAvailable, blind, roomId, winners]);
  // 승자 족보 세팅
  useEffect(() => {
    if (winners.length > 0 && Object.keys(winnerCards).length > 0 && communityCards.length > 0) {
      const ceremonyCards: string[] = [];
      let ceremonyRanking = ''
      for (let winner of winners) {
        const cards = winnerCards[winner.userId];
        const solved = Hand.solve([
          ...cards.map(x => CARD_INFO[x]),
          ...communityCards.map(x => CARD_INFO[x])
        ])

        const targetCards = solved.cards.map((x: any) => toCardString(x.value, x.suit));
        for (let card of targetCards) {
          if (!ceremonyCards.includes(card)) {
            ceremonyCards.push(card);
          }
        }

        if (winner.userId === myInfo?.userId || ceremonyRanking === '') {
          ceremonyRanking = solved.descr;
        }
      }

      setCeremonyCards(ceremonyCards);
      setCeremonyRanking(ceremonyRanking);
    }
  }, [myInfo?.userId, winners, winnerCards, communityCards]);


  const handleLeave = useCallback(async () => {

    if (room?.type === ROOM_TYPE.RING && myInfo) {
      if (Number(myInfo?.stackSize) >= 0 && Number(myInfo?.handsCount) >= 0) {
        const res = await requestGetHandCuttingList();
        const handCuttingList = res.list;
        let settle = myInfo.stackSize;
        let buyin = myInfo.buyin;
        let winAmount = settle - buyin;
        let lossPercentage = 0;
        let maxHands = 0;

        let r = res.list.sort((a, b) => {
          return a.handCount - b.handCount
        })
        maxHands = r[r.length - 1].handCount;

        for (let v of handCuttingList) {
          if (myInfo.handsCount <= v.handCount) {
            lossPercentage = v.lossPercentage
            let lossAmount = Math.floor(winAmount * (lossPercentage) / 100);
            settle = settle - lossAmount;
            break;
          }
        }

        if (winAmount < 0) {
          settle = myInfo.stackSize;
          winAmount = 0;
        }

        const leave = await new Promise<boolean>((r) => {
          playSFX(Sounds.SFX_WINDOW_OPEN);
          openDialog({
            title: t("game over"),
            component: <LeaveSettlement
              hands={myInfo.handsCount}
              stack={myInfo?.stackSize}
              buyin={buyin}
              winAmount={winAmount}
              lossPercentage={lossPercentage}
              settle={settle}
              maxHands={maxHands}
              lack={maxHands >= myInfo.handsCount}
            />,
            forceMobile: true,
            type:'custom',
            confirm: true,
            confirmText: t('Settlement'),
            cancelText: t('Cancel'),
            onConfirm: () => {
              playSFX(Sounds.SFX_WINDOW_CLOSE)
              r(true);
            },
            onCancel: () => {
              playSFX(Sounds.SFX_WINDOW_CLOSE)
              r(false);
            }
          });
        });

        if (!leave) {
          return;
        }
      }
    }
    const data = await requestLeaveRoom(roomId);
    if (data.result) {
      history.replace("/m/holdem");
    }
  }, [roomId, room?.type, myInfo?.stackSize]);

  const handleShowOption = useCallback(() => {
    setShowOptionModal(true);
  }, []);

  const handleToggleBlindWait = useCallback(async () => {
    playSFX(Sounds.SFX_CLICK_INGAME, true)
    await requestSetBlindWait(roomId, !myInfo?.blindWait);
  }, [myInfo?.blindWait]);

  const handleSitDown = useCallback(async (s: number) => {
    if (myInfo?.status !== ROOM_JOIN_STATUS.PLAYING) {
      setBuyInSeat(s);
    }
  }, [myInfo?.status]);

  const handleBuyIn = useCallback(async (amount: number) => {
    const seat = buyInSeat;
    setBuyInSeat(-1);
    setGlobalLoading(true);
    let data = await requestBuyin(roomId, amount, seat);
    if (data.result == -1) {
      openDialog({
        title: t('안내'),
        text: t('알수없는 오류로 바이인을 실패했습니다.')
      });
    } else if (data.result == -2) {
      openDialog({
        title: t('안내'),
        text: t('보유 머니가 부족하여 바이인을 실패했습니다.')
      });
    } else if (data.result == -3) {
      openDialog({
        title: t('안내'),
        text: t('이미 해당 자리에 누군가 먼저 앉아서 바이인을 실패하였습니다.')
      });
    }

    requestRoomInfo(roomId);
    requestMyStatusInRoom(roomId);
    setGlobalLoading(false);
    playSFX(Sounds.SFX_WINDOW_CLOSE);
  }, [buyInSeat]);

  useEffect(()=>{
    if(showHistory) {
      playSFX(Sounds.SFX_WINDOW_OPEN);
    }else{
      playSFX(Sounds.SFX_WINDOW_CLOSE);
    }
  }, [showHistory])

  useEffect(() => {
    if(showHandDesc){
      playSFX(Sounds.SFX_WINDOW_OPEN);
    }else{
      playSFX(Sounds.SFX_WINDOW_CLOSE);
    }
  }, [showHandDesc]);

  useEffect(() => {
    if(showEmoticonSelector){
      playSFX(Sounds.SFX_WINDOW_OPEN);
    }else{
      playSFX(Sounds.SFX_WINDOW_CLOSE);
    }
  }, [showEmoticonSelector]);

  const handleRejoinGame = useCallback(async () => {
    if (!isRebuyInAvailable || !myInfo) {
      return;
    }

    if (room?.type === ROOM_TYPE.RING) {
      setBuyInSeat(myInfo.seat);
    } else if (room?.type === ROOM_TYPE.TOURNAMENT) {
      // 0원되면 그냥 쫒겨남
      // openDialog({
      //   title: t('바이인 안내'),
      //   text: `리바이인 시 ${room.groupData.rebuyinChip.toLocaleString()} 을 얻고, ${room.groupData.buyinPrice} 를 지출하게 됩니다.<br/>현재 머니 : ${myProfileInfo?.money}`
      //   confirm: true,
      //   confirmText: '리바인',
      //   cancelText: '취소',
      //   onConfirm: async () => {
      //     await requestRebuyinTournament(room.groupId);
      //   }
      // });
    }
  }, [room, myInfo, isRebuyInAvailable, myProfileInfo]);

  const handleTryBet = useCallback(async (type: BET_TYPE, betAmount: number) => {
    playSFX(Sounds.SFX_CLICK_INGAME);
    await requestTryBet(roomId, type, betAmount);
    setShowAutoActionForRequest(false)
    setBetData(null);
  }, [roomId]);

  const handleOpenProfile = useCallback((userId: number) => {
    setProfileUserId(userId);
  }, []);

  const handleSendEmoticon = useCallback(async (emoticonId?: number) => {
    if (emoticonId) {
      await requestRequestEmoji(roomId, emoticonId);
    }
    setShowEmotionHotKey(false)
    setShowEmoticonSelector(false);
  }, [roomId]);


  if (!room) {
    return <></>;
  }
  const showActionButton = (showActions &&
    !(
      (
        autoAction === 'check/fold' && (
          betData!.legalAct.includes('check') || betData!.legalAct.includes('fold')
        )
      ) ||
      (
        autoAction === 'autocall' && (
          betData!.legalAct.includes('call'))
      )
    )
  )
  const showWaitButton = (
    (myInfo !== undefined) && !myInfo.waitGame && (
      playersBetting.length > 0 ?
        (
          room.currentRound !== 'pre-flop' ? (
            (playersBetting.sort((a, b) => b.bet - a.bet)[0].bet === 0) ||
            (playersBetting.sort((a, b) => b.bet - a.bet)[0].bet > myInfo.bet)
          ) : (
            (
              playersAct[myInfo.userId] === BET_TYPE.BB ||
              players.find(_ => _?.userId === myInfo.userId)?.lastAction === BET_TYPE.BB
            ) ||
            playersBetting.sort((a, b) => b.bet - a.bet)[0].bet > myInfo.bet
          )
        ) : true
    ) && (showAutoActionForRequest) && (showAutoAction) && (room.roomStatus === ROOM_STATUS.INGAME) &&
    (myInfo.status !== ROOM_JOIN_STATUS.FOLD) && (!myInfo.blindWait) && (myInfo.stackSize > 0)
  )

  const StatusBoardComponent = <StatusBoard
    roomType={room.type}
    SB={blind.small}
    BB={blind.big}
    ante={blind.ante}
    rakeType={room.groupData.rakeType}
    rake={room.groupData.rake}
    myRank={myRank}
    totalMember={totalMember}
    straddle={room.groupData.isStraddle}
    timerText={timerText}
    blindUpTimerText={blindTimerText}
  />;

  return <>
    <ParticleBackground tableStyle={tableStyle}/>
    <Wrapper>
      <Header>
        <LeaveButton onClick={handleLeave}>
          <img alt='out' src='/images/ic_out.png'/>
        </LeaveButton>
        {
          room && room.handNumber > 0 && <HistoryButton onClick={()=>setShowHistory(true)}>
            <img alt='history' src='/images/Icon/History.png'/>
          </HistoryButton>
        }
        <Flex>
          {
            orientation === 'landscape' && (
              StatusBoardComponent
            )
          }
        </Flex>
        {
          orientation === 'landscape' && (
            <HandRankingHelpButton onClick={()=> {
              setShowHandDesc(true)
            }} src='/images/Card%20info.png'/>
          )
        }
        {
          orientation === 'portrait' && (
            StatusBoardComponent
          )
        }
        {
          false && <OptionButton onClick={handleShowOption}><img src="/images/ic_option.svg"/></OptionButton>
        }
      </Header>
      <GameTable>
        {/*<div className='video-background'>
          <video src='/images/particle_fire.mp4' muted={true} autoPlay={true} loop={true}/>
        </div>*/}
        {
          tableStyle > 0 && <img
            className="game-table"
            src={`/images/game/table_${orientation}_type_${tableStyle}.png`}
          />
        }
        <DealerButton className="dealer-button"/>

        <div className="table-hole"/>

        {
          players.map((p, i) => {
            const userId = p?.userId ?? -1;
            const isMe = userId === myInfo?.userId;
            const disabled = !p && !!myInfo && myInfo?.status !== ROOM_JOIN_STATUS.OBSERVE;
            const isBetNow = betData?.userId == userId;
            const winningRate = calculator.winningRates.find(x => x.userId === userId);

            const cards = [];
            if (isMe && Array.isArray(myCards)) {
              cards.push(...myCards);
            } else if (winnerCards[userId]) {
              cards.push(...winnerCards[userId]);
            }

            const isChipLeader = ((chipLeader?.stackSize ?? 0) !== 0) && chipLeader?.userId === userId;
            let blindPos: 'SB' | 'BB' | null = null
            const isAllIn = players.find(x => x?.userId === userId && x.status === ROOM_JOIN_STATUS.PLAYING)?.stackSize === 0

            if(room && p){
              if(room.bbPosition === p.seat){
                blindPos = 'BB'
              }
              if(room.sbPosition === p.seat){
                blindPos = 'SB'
              }
            }
            return <Player
              tableStyle={tableStyle}
              isAllIn={isAllIn}
              blindPos={blindPos}
              isChipLeader={showChipLeader && isChipLeader}
              key={i}
              idx={i}
              player={p}
              BB={blind.big}
              act={playersAct[userId]}
              me={isMe}
              betNow={isBetNow}
              disabled={disabled}
              winners={winners}
              rate={winningRate?.rate}
              communityCards={communityCards}
              cards={cards}
              onClickSeat={handleSitDown}
              onClickProfile={handleOpenProfile}
              onClickEmoticon={() => setShowEmoticonSelector(true)}
              maxTableMember={maxTableMember}
            />;
          })
        }
        {
          Array.from({length: maxTableMember}).map((v, i) => {
            const p = players[i];
            const userId = p?.userId ?? -1;
            let amount = 0;
            let isAllIn = false;
            if (p && playersBetting) {
              const playerBetting = playersBetting.find(q => q.id == userId);
              if (playerBetting) {
                isAllIn = playerBetting.bet > 0 && playerBetting.stackSize === 0
                amount = playerBetting.bet;
              }
            }
            return <PlayerPot seat={i} key={i} userId={userId} bb={blind.big} amount={amount} isAllIn={isAllIn}/>;
          })
        }

        <FieldPots total={totalPotSize} pots={pots} BB={blind.big}>
        </FieldPots>
        <CommunityCards className="community-cards-wrapper" data-cards={innerCommunityCards.length}
                        noAnimation={noRoundAnim}>
          {
            Array.from({length: 5}).map((_, i) => {
              const showCard = innerCommunityCards[i] >= 0;
              if (showCard) {
                return <PokerCard
                  tableStyle={tableStyle}
                  isCommunityCard={true}
                  key={i}
                  className="community-card"
                  data-open={showCard}
                  card={innerCommunityCards[i]}
                  flip
                  delay={noRoundAnim ? -1 : 1000}
                  startRemoveAnimation={startRemoveCommunityCards ? (innerCommunityCards.length - i -1) * 200 : undefined}
                />;
              } else {
                return <div key={i}/>;
              }
            })
          }
          {
            ceremonyRanking && (
              <WinnerHandRanking>
                <div>{ceremonyRanking}</div>
              </WinnerHandRanking>
            )
          }
          {
            orientation === 'portrait' &&
             <HandRankingHelpButton onClick={()=> {
              setShowHandDesc(true)
            }} src='/images/Card%20info.png'/>
          }

        </CommunityCards>
        <CoinMoveWrapper className="coin-move-wrapper"/>
        {
          ceremonyRanking && (
            <CeremonyDimmer/>
          )
        }
        {
          ((orientation === 'portrait' && !showEmoticonSelector) ||orientation === 'landscape') && (
            <InGameButtonWrapper className="ingame-button-wrapper">
              <ActionButtons
                bb={blind.big}
                show={showActionButton}
                onClickTryBet={handleTryBet}
                myInfo={myInfo!}
                room={room}
                legalActs={innerLegalActs}
                totalPot={totalPotSize}
              />
              <WaitButtonWrapper show={!showActionButton && showWaitButton}>
                <InGameButton mode={'prebet'} checked={autoCheckFold} onChecked={() => {
                  playSFX(Sounds.SFX_CLICK_INGAME, true)
                  if (autoCheckFold) {
                    setAutoCheckFold(false)
                  } else {
                    setAutoCheckFold(true)
                    setAutoCall(false)
                    setAutoCallAny(false)
                  }
                }}>
                  Check/Fold
                </InGameButton>
                <InGameButton mode={'prebet'} checked={autoCall} onChecked={() => {
                  playSFX(Sounds.SFX_CLICK_INGAME, true)
                  if (autoCall) {
                    setAutoCall(false)
                  } else {
                    setAutoCall(true)
                    setLastBet(room?.currentBet)
                    setAutoCheckFold(false)
                    setAutoCallAny(false)
                  }
                }}>CALL<br/>CURRENT
                </InGameButton>
                <InGameButton mode={'prebet'} checked={autoCallAny} onChecked={() => {
                  playSFX(Sounds.SFX_CLICK_INGAME, true)
                  if (autoCallAny) {
                    setAutoCallAny(false)
                  } else {
                    setAutoCallAny(true)
                    setAutoCall(false)
                    setAutoCheckFold(false)
                  }
                }}>CALL ANY
                </InGameButton>
              </WaitButtonWrapper>

              {
                showJoinButtonRing && (
                  <WaitButtonWrapper show={showJoinButtonRing}>
                    {
                      isRebuyInAvailable ? (
                        <InGameButton mode={'prebet'} onClick={handleRejoinGame}>
                          {t('Join the game')}
                        </InGameButton>
                      ) : (
                        <InGameButton mode={'prebet'} checked={myInfo?.blindWait} onChecked={handleToggleBlindWait}>
                          {t('blind standby')}
                        </InGameButton>
                      )
                    }
                  </WaitButtonWrapper>
                )
              }
              {
                showJoinButtonTournament && (
                  <JoinButtonWrapper>
                    {
                      // isRetired ? (
                      //   <InGameButton onClick={handleRejoinGame}>
                      //     게임 참여하기
                      //   </InGameButton>
                      // ) : null
                    }
                  </JoinButtonWrapper>
                )
              }
            </InGameButtonWrapper>
          )
        }
      </GameTable>
      <EmoticonHotKeyWrapper show={showEmotionHotKey && !showJoinButtonRing}>
        {[13, 17, 2].map((x, i) => {
          return <img key={i} className='item' src={`/images/emoji/${x}.svg`} onClick={()=>handleSendEmoticon(x)}/>
        })}
      </EmoticonHotKeyWrapper>

      {
        room.type === ROOM_TYPE.RING && (
          <ModalContainer
            show={buyInSeat !== -1} onBackdropClick={() => setBuyInSeat(-1)}>
            <BuyInModal
              onClose={() => {
                playSFX(Sounds.SFX_WINDOW_CLOSE)
                setBuyInSeat(-1)
              }}
              bigBlind={blind.big}
              minBuyIn={room.groupData.minBuyin}
              maxBuyIn={room.groupData.maxBuyin}
              onClickBuyIn={handleBuyIn}
            />
          </ModalContainer>
        )
      }
      {
        isRestTime && (
          <RestTimePopup>{t('휴식시간입니다.')}</RestTimePopup>
        )
      }
      <RightDrawer
        opened={showHistory}
        onOpen={() => setShowHistory(true)}
        onClose={() => setShowHistory(false)}
      >
        <GameHistory
          groupId={room.groupId}
          roomId={roomId}
          handNumber={room?.handNumber}
          currentRound={room?.currentRound}
          maxTableMember={room?.groupData.maxTableMember}
          onClose={() => setShowHistory(false)}
          isOpen={showHistory}
        />
      </RightDrawer>
      <ModalContainer show={showHandDesc} onBackdropClick={() => setShowHandDesc(false)}>
        <HandDescModal
          onClose={()=>setShowHandDesc(false)}
        />
      </ModalContainer>
      <ModalContainer show={profileUserId !== -1} onBackdropClick={() => setProfileUserId(-1)}>
        <ProfileModal groupId={room.groupId} userId={profileUserId} onClose={() => setProfileUserId(-1)}/>
      </ModalContainer>
      <ModalContainer show={showOptionModal} onBackdropClick={() => setShowOptionModal(false)}>
        <SettingModal onClose={() => setShowOptionModal(false)}/>
      </ModalContainer>
      <ModalContainer show={showEmoticonSelector} noDim onBackdropClick={() => setShowEmoticonSelector(false)}>
        <EmoticonSelector onClose={handleSendEmoticon}/>
      </ModalContainer>
    </Wrapper>
  </>;
}
