import {Sounds} from "./sound";

const CHIP_SIZES = [
  5_000_000,
  1_000_000,
  500_000,
  100_000,
  50_000,
  10_000,
  5_000,
  1_000,
  100,
]; // 5000000, 1000000, 500000, 100000, 50000, 10000, 5000, 1000, 100
const MAX_CHIP = 10;

export type Chips = {
  size: number;
  amount: number;
}[];

export function convertChips(n: number): string {
  if (n === 5000000) {
    return 'Black';
  } else if (n === 1000000) {
    return 'Gold';
  } else if (n === 500000) {
    return 'Purple';
  } else if (n === 100000) {
    return 'Pink';
  } else if (n === 50000) {
    return 'Orange';
  } else if (n === 10000) {
    return 'Green';
  } else if (n === 5000) {
    return 'Blue';
  } else if (n === 1000) {
    return 'Red';
  } else if (n === 100) {
    return 'White';
  } else {
    return 'White';
  }
}

export function minimumChips(n: number): Chips {
  const chips: Chips = [];

  for (let size of CHIP_SIZES) {
    let cnt = Math.floor(n / size);
    if (cnt > 0) {
      n %= size;
    }

    while (cnt > 0) {
      chips.push({
        size: size,
        amount: Math.min(cnt, MAX_CHIP)
      });

      cnt -= MAX_CHIP;
    }
  }
  return chips;
}

export function moveChipsSound(n: number): string {
  const soundList = [Sounds.SFX_BET_1,Sounds.SFX_BET_2,Sounds.SFX_BET_3,Sounds.SFX_BET_4]
  return soundList[Math.floor(Math.random() * soundList.length)];
}
