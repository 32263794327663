import {useRecoilValue} from "recoil";
import produce from 'immer';
import {userIdSelector} from "../recoil/MyInfo";
import {useHistory, useLocation} from "react-router-dom";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import useRefreshRoomInfo from "../api/from_server_game_refreshRoomInfo";
import {GamePlayer, PlayersBettings, ROOM_JOIN_STATUS, ROOM_STATUS, ROOM_TYPE, RoomInfo} from "../dataset";
import {calcLevel, calcPlayTime} from "../utils/tournament";
import {parseDatetime} from "../constants/moment";
import {requestCurrentRoom} from "../api";
import {bigBlindToGameStyle} from "../utils/ring";

function useLoadGame() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = useRecoilValue(userIdSelector);
  const [roomData] = useRefreshRoomInfo();
  const [room, setRoom] = useState<RoomInfo>();
  const [isWait, setWait] = useState<boolean>(false);
  const roomStatusTimerRef = useRef<NodeJS.Timer>();

  const roomId = useMemo<number>(() => {
    return Number(searchParams.get("id")!);
  }, [searchParams]);

  const myInfo = useMemo<GamePlayer | undefined>(() => {
    return room?.players.find(x => {
      if(x === null || x === undefined) {
        return false
      }else if(Object.keys(x).includes('userId')) {
        return x.userId === userId
      }else{
        return false
      }
    });
  }, [room?.players, userId]);

  const maxTableMember = useMemo(() => {
    if (!room?.groupData) {
      return 0;
    }

    if (room?.type === ROOM_TYPE.TOURNAMENT) {
      const max = Math.max(...room?.players.map(x => x.seat)) + 1;
      if (max === room.groupData.finalTableMember) {
        return room.groupData.finalTableMember;
      }
    }
    return room.groupData.maxTableMember;
  }, [room?.type, room?.groupData, room?.players.length]);

  const players = useMemo<(GamePlayer | undefined)[]>(() => {
    if (!room) {
      return [];
    }

    const arr = [];
    let me;
    for (let i = 0; i < maxTableMember; i++) {
      arr[i] = room.players.find(x => {
        if(x === null || x === undefined) {
          return false
        }else if(Object.keys(x).includes('seat')) {
          return x.seat === i
        }else{
          return false
        }
      });
      if (arr[i]?.userId === userId) {
        me = arr[i];
      }
    }

    if (!me || me.status === ROOM_JOIN_STATUS.OBSERVE) {
      return arr;
    }

    // 내가 자리에 앉아 있고, 관전 상태가 아니면 배열을 잘라 내가 0번 자리에 오도록 한다.
    return arr.splice(me.seat).concat(arr);
  }, [room?.groupData.maxTableMember, room?.players, userId]);

  // SB, BB 금액
  const blind = useMemo<{
    small: number,
    big: number,
    ante: number,
  }>(() => {
    const ret = {
      small: 0,
      big: 0,
      ante: 0,
    };

    if (room?.type === ROOM_TYPE.RING) {
      ret.small = room?.groupData.blind[0];
      ret.big = room?.groupData.blind[1];
      if (room?.groupData.isAnte) {
        ret.ante = room?.groupData.ante;
      }
    } else if (room?.type === ROOM_TYPE.TOURNAMENT) {
      const {
        playTimeSeconds,
        restTimeSeconds
      } = room.groupData.timeStructure;
      const playTime = calcPlayTime(parseDatetime(room.groupData.startedAt), playTimeSeconds, restTimeSeconds);
      const level = calcLevel(playTime, room.groupData.blindStructure);
      ret.small = room.groupData.blindStructure[level][0];
      ret.big = room.groupData.blindStructure[level][1];
      ret.ante = room.groupData.blindStructure[level][2];
    }

    return ret;
  }, [room?.type, room?.groupData]);

  const gameStyle = useMemo<number>(() => {
    if (room?.type === ROOM_TYPE.TOURNAMENT) {
      return 7;
    }

    return bigBlindToGameStyle(blind?.big ?? 0);
  }, [room?.type, blind])

  // 방이 존재하지 않으면 로비로 보내기
  useEffect(() => {
    requestCurrentRoom(roomId).then((res) => {
      if (!res?.exist) {
        history.replace('/');
      }
    })
  }, [roomId]);

  useEffect(() => {
    if (roomData && roomData.roomId === roomId) {
      setRoom(roomData);
    }
  }, [roomData, roomId]);

  // 게임 대기 상태 설정
  useEffect(() => {
    if (room?.roomStatus === 0) {
      roomStatusTimerRef.current = setTimeout(() => {
        setWait(true);
      }, 2000);
    } else {
      clearTimeout(roomStatusTimerRef.current);
      setWait(false);
    }
  }, [room?.roomStatus])

  const updatePlayer = useCallback(async (seat: number, player: GamePlayer) => {
    if (!room?.players) {
      return;
    }

    setRoom(produce(d => {
      if (!d?.players) {
        return;
      }

      const idx = room.players.findIndex(x => x.seat === seat);
      if (!player) {
        if (idx !== -1) {
          d.players.splice(idx, 1);
        }
      } else {
        if (idx !== -1) {
          d.players[idx] = player;
        } else {
          d.players.push(player);
          d.players.sort((a, b) => a.seat - b.seat);
        }
      }
    }));
  }, [room?.players]);

  const updateStackSize = useCallback((userId: number, stackSize: number) => {
    if (!room?.players) {
      return;
    }

    setRoom(produce(d => {
      if (d) {
        const idx = d.players.findIndex(x => x.userId === userId);
        if (idx !== -1) {
          d.players[idx].stackSize = stackSize;
        }
      }
    }));
  }, [room?.players]);

  return {
    roomId,
    room,
    isWait,
    players,
    myInfo,
    blind,
    gameStyle,
    updatePlayer,
    updateStackSize,
    maxTableMember
  };
}

export default useLoadGame;
