import React, {useCallback, useEffect, useRef} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 100;
`;

const Dim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s linear;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(100%);
  transition: all 0.2s ease-out;
  will-change: transform;
`;

function RightDrawer({opened, noSwipe, onOpen, onClose, children}: { opened?: boolean; noSwipe?: boolean; onOpen?: () => void; onClose?: () => void; children: any; }) {
  const dimmerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const prevState = useRef<{
    originX: number;
  }>();
  const drawerState = useRef<{
    translateX: number,
    opening: boolean,
    opened: boolean,
    closing: boolean,
  }>({
    translateX: 0,
    opening: false,
    opened: false,
    closing: false,
  });

  const openDrawer = useCallback(() => {
    if (dimmerRef.current && contentRef.current) {
      contentRef.current.style.transform = `translateX(0)`;
      dimmerRef.current.style.opacity = '0.5';
      dimmerRef.current.style.pointerEvents = 'initial';
      drawerState.current.opened = true;
      drawerState.current.opening = false;
      onOpen && onOpen();
    }
  }, [onOpen]);


  const closeDrawer = useCallback(() => {
    if (dimmerRef.current && contentRef.current) {
      contentRef.current.style.transform = '';
      dimmerRef.current.style.opacity = '';
      dimmerRef.current.style.pointerEvents = '';
      drawerState.current.opened = false;
      drawerState.current.closing = false;
      onClose && onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (dimmerRef.current && contentRef.current) {
      if (drawerState.current?.opening || drawerState.current?.closing) {
        return;
      }

      if (opened) {
        openDrawer();
      } else {
        closeDrawer();
      }
    }

  }, [opened, openDrawer, closeDrawer]);

  useEffect(() => {
    if (!dimmerRef.current || !contentRef.current) {
      return;
    }

    const handleMouseEvent = (e: any) => {
      if (!dimmerRef.current || !contentRef.current) {
        return;
      }

      if (noSwipe) {
        return;
      }

      if (e.type === 'mousedown' || e.type === 'touchstart') {
        const clientX = e.touches ? e.touches[0].clientX : e.clientX;

        prevState.current = {
          originX: clientX
        };

        if (drawerState.current?.opened) {
          drawerState.current = {
            translateX: 0,
            opening: false,
            opened: true,
            closing: true,
          };
        } else {
          if (clientX >= window.innerWidth - 20) {
            drawerState.current = {
              translateX: 100,
              opening: true,
              opened: false,
              closing: false,
            };
          }
        }

      } else if (e.type === 'mousemove' || e.type === 'touchmove') {
        if (!prevState.current || !drawerState.current) {
          return;
        }

        const clientX = e.touches ? e.touches[0].clientX : e.clientX;
        const dy = clientX - prevState.current.originX;
        const portion = dy / contentRef.current.offsetWidth * 100;

        let translateX;
        if (drawerState.current.opening) {
          if (Math.abs(dy) < 3) {
            return;
          }

          translateX = 100 + portion;
          if (translateX > 100 || translateX < 0) {
            return;
          }
          drawerState.current.translateX = translateX;

          contentRef.current.style.transition = `none`;
          contentRef.current.style.transform = `translateX(${translateX}%)`;
          dimmerRef.current.style.opacity = (0.5 * -portion / 100).toString();

        } else if (drawerState.current.closing) {
          translateX = portion;
          if (translateX > 100 || translateX < 0) {
            return;
          }
          drawerState.current.translateX = translateX;

          contentRef.current.style.transition = `none`;
          contentRef.current.style.transform = `translateX(${translateX}%)`;
          dimmerRef.current.style.opacity = (0.5 - (0.5 * portion / 100)).toString();
        }
      } else if (e.type === 'mouseup' || e.type === 'touchend') {
        if (!prevState.current || !drawerState.current) {
          return;
        } else if (drawerState.current.translateX === undefined) {
          return;
        }

        e.stopPropagation();

        prevState.current = undefined;
        contentRef.current.style.transition = '';

        if (drawerState.current.opening) {
          if (drawerState.current.translateX < 90) {
            openDrawer();
          } else {
            contentRef.current.style.transform = ``;
            drawerState.current.opening = false;
          }
        }

        if (drawerState.current.closing) {
          if (drawerState.current.translateX > 10) {
            closeDrawer();
          } else {
            contentRef.current.style.transform = `translateX(0)`;
            dimmerRef.current.style.opacity = '0.5';
            dimmerRef.current.style.pointerEvents = 'initial';
            drawerState.current.closing = false;
          }
        }
      }
    };

    const el = opened ? contentRef.current : window;
    el.addEventListener('mousedown', handleMouseEvent);
    el.addEventListener('touchstart', handleMouseEvent);
    window.addEventListener('mousemove', handleMouseEvent);
    window.addEventListener('touchmove', handleMouseEvent);
    window.addEventListener('mouseup', handleMouseEvent);
    window.addEventListener('touchend', handleMouseEvent);

    return () => {
      el.removeEventListener('mousedown', handleMouseEvent);
      el.removeEventListener('touchstart', handleMouseEvent);
      window.removeEventListener('mousemove', handleMouseEvent);
      window.removeEventListener('touchmove', handleMouseEvent);
      window.removeEventListener('mouseup', handleMouseEvent);
      window.removeEventListener('touchend', handleMouseEvent);
    };
  }, [opened, noSwipe, onOpen, openDrawer, closeDrawer]);

  return <Wrapper>
    <Dim ref={dimmerRef} onClick={onClose}/>
    <Content ref={contentRef}>
      {children}
    </Content>
  </Wrapper>;
}

export default RightDrawer;