import React from "react";
import styled from "styled-components";
import {ReactFontSizeByTextLength} from "../../utils/ReactFontSizeByTextLength";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {fixedNumber, toEasternNumeral} from "../../utils/common";
import {useRecoilState} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {convertChips, minimumChips} from "../../utils/chip";
import Chips from "./Chips";

const Wrapper = styled.div<{ isLeft: boolean; show: boolean; isAllIn: boolean}>`
  position: absolute;
  width: 60px;
  height: 16px;
  
  left: -100%;
  top: -100%;
  user-select: none;
  pointer-events: none;
  display: flex;
  ${p => p.show ? `
    opacity: 1;
  ` : `
    opacity: 0;
  `}
  
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;

  background: rgba(24, 24, 24, 0.50);
  padding: 0 4px;
  ${p => p.isLeft ? `
  flex-direction: row;
  ` : `
  flex-direction: row-reverse;
  `}
  @media ${MEDIA_DESKTOP} {
    width: 100px;
    height: 34px;
    border-radius: 22px;
  }
  img {
    &[data-amount="0"] {
      opacity: 0;
    }
  }

  > span {
    width: 100%;
    flex-grow: 1;
    background: blue;
    
  }
  @media ${MEDIA_DESKTOP} {
    .chips {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      ${p => p.isLeft ? `
        left: -10px;
      ` :`
        right: -3px;
      `}
    }
  }
  

  .amount {
    ${p => p.isLeft ? `
      padding-left: 4px;
    ` : `
      padding-right: 4px;
    `}
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    color: #42FF00;
    ${p => p.isAllIn ? `color: #FF2021;` : ``}
    text-align: right;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.30);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.2px;
    transition: all 0.3s;
    padding-bottom: 1px;
    @media ${MEDIA_DESKTOP} {
      text-align: right;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.30);
      font-family: Pretendard;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      
    }
  }
`;
function PlayerPot(
  {
    isAllIn,
    amount,
    userId,
    seat,
    bb
  }: {
    amount: number,
    userId: number
    seat: number,
    bb: number,
    isAllIn: boolean
  }
): JSX.Element {
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const orientation = useScreenOrientation();
  return <Wrapper isAllIn={isAllIn} className={'game-player-pot'} isLeft={seat < 5} show={amount > 0}>
    <Chips className={`chips pot-${userId}`} amount={amount} />
    {
      amount > 0 && (
        <div className='amount'>
          <ReactFontSizeByTextLength changePerChar={orientation === 'portrait' ? 3 : 4}>
            {
              setting.alwaysBB ?
                <span>{fixedNumber(amount / bb, 2).toLocaleString()} BB</span> :
                <span>{amount.toLocaleString()}</span>
            }
          </ReactFontSizeByTextLength>
        </div>
      )
    }
  </Wrapper>
    ;
}

export default PlayerPot;
