import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {client, requestCurrentRooms, requestJoinRoom, useSocketIsConnect} from "../api";
import {useRecoilState, useSetRecoilState} from "recoil";
import {globalLoadingState} from "../recoil/Loading";
import useDialog from "../hooks/useDialog";
import {connectionState} from "../recoil/Connection";
import {setAckListener as userDepositTxAccepted} from "../api/from_server_ping_userAcceptDepositTx"

const FromServerToast: React.FC = () => {

    useEffect(()=>{
        userDepositTxAccepted((data:any)=>{
            console.log("userDepositTxAccepted", data)
        });
        return ()=>userDepositTxAccepted(null);
    })

    return <>
    </>;
  }
;
export default FromServerToast;