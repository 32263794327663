import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{
  rate: number
}>`
  width: 100%;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.40);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;

  ${p => p.rate >= 0 && p.rate < 60 && `
    background: linear-gradient(90deg, rgba(24, 24, 24, 0.00) -2.1%, rgba(24, 24, 24, 0.70) 48.95%, rgba(24, 24, 24, 0.00) 100%);
  `};

  ${p => p.rate >= 60 && p.rate <= 100 && `
    background: linear-gradient(90deg, rgba(255, 215, 3, 0.00) 0%, #FFD703 48%, rgba(255, 215, 3, 0.00) 100%);
  `};
  @media ${MEDIA_DESKTOP} {
    font-size: 24px;
    height: 36px;
  }
`;

function WinningRate({rate, ...p}: {
  rate?: number
}) {
  if (rate === undefined) {
    return null;
  }

  return <Wrapper rate={rate} {...p}>
    {rate}%
  </Wrapper>
}

export default WinningRate;
