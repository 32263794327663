export const bigBlindToGameStyle = (bigBlind: number): number  => {
  if (bigBlind <= 2000) {
    return 1;
  } else if (bigBlind <= 5000) {
    return 2;
  } else if (bigBlind <= 10000) {
    return 3;
  } else if (bigBlind <= 20000) {
    return 4;
  } else if (bigBlind <= 50000) {
    return 5;
  } else if (bigBlind <= 100000) {
    return 6;
  }

  return 1;
}