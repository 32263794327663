import styled from "styled-components";
import 'swiper/css';
import {useCallback, useEffect, useMemo, useState} from "react";
import {
  requestJoinRingGroup,
  requestRingGroupList,
  requestTournamentGroupList,
  useSocketIsConnect
} from "../api";
import useRingGroupListRefresh from "../api/from_server_game_refreshRingGroupList";
import {useSetRecoilState} from "recoil";
import {globalLoadingState} from "../recoil/Loading";
import useDialog from "../hooks/useDialog";
import {useHistory} from "react-router-dom";
import {toEasternNumeral} from "../utils/common";
import {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import TabEffect from "../components/TabEffect";

const STRBadge = styled.div`
  width: 29px;
  height: 13px;
  position: relative;
  >.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 21px;
    object-fit: contain;
  }
`
const HoldemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  position: relative;

  > .background {
    position: absolute;
    width: 355vw;
    aspect-ratio: 1527/587;
    left: 50%;
    top: -15%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 0;

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      object-fit: contain;
      opacity: 0;
      animation: _0dfdb03 2s ease-out .5s forwards, _b341c19 20s ease-in-out 2.5s infinite alternate;
    }

    > .mobile {
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
    }

    > .pc {
      display: none;
      @media ${MEDIA_DESKTOP} {
        display: block;
      }
    }
  }

  @keyframes _0dfdb03 {
    0% {
      opacity: 0;
      transform: scale(.95);
    }
    100% {
      opacity: .6;
      transform: scale(1.1);
    }
  }
  @keyframes _b341c19 {
    0% {
      opacity: .6;
      transform: scale(1.1);
    }
    100% {
      opacity: .6;
      transform: scale(.95);
    }
  }

  > .banner-wrapper {
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
    height: 120px;
    object-fit: contain;
  }

  .swiper {
    width: 100%;
    flex-shrink: 0;
  }

  .swiper-wrapper {
    width: 100%;
  }

  .swiper-pagination {
    bottom: 4px;

    .swiper-pagination-bullet {
      margin: 0 2px;
      width: 20px;
      height: 2px;
      border-radius: 0;
      opacity: 0.3;
      background: white;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
`

const EnterButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 480px;
  height: 60px;
  flex-shrink: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 104px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  background: #000;
  z-index: 3;
  @media ${MEDIA_DESKTOP} {
    display: none;
  }
`
const EnterButton = styled.div`
  display: flex;
  width: 280px;
  height: 100%;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 0;
  background: #000;
  cursor: pointer;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const CategoryTabWrapper = styled.div`
  margin-top: 16px;
  @media ${MEDIA_DESKTOP} {
    margin-top: 44px;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 8px;
  transition: all 0.2s;
  z-index: 2;

  > .item {
    position: relative;
    cursor: pointer;
    color: #000;
    position: relative;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 13.2px */
    padding: 10px;
    border-radius: 0px;
    border: 1px solid #000;
    @media ${MEDIA_DESKTOP} {
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; 
      padding: 10px;
    }
    > .effect {
      display: none;
    }    
  }

  > .selected {
    background: rgb(0 0 0);
    color: #fff;
  }
`
const RingCardList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 24px;
  justify-items: center;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 60px;
  z-index: 2;
  @media ${MEDIA_DESKTOP} {
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
`;
const RingCard = styled.div<{ selected: boolean}>`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 26.1vw;
  max-width: 125px;
  aspect-ratio: 94/128;
  flex-shrink: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;

  ${p => p.selected ? `
    width: 28.8vw;
    aspect-ratio: 104/142;
    @media ${MEDIA_DESKTOP} {
      width: 26.1vw;
      max-width: 125px;
      aspect-ratio: 94/128;
    }
    border-radius: 0;
    border: 3px solid #a1a1a1;
  ` : ``}
  > .background {
    display: none;
  }

  > .content {
    width: 100%;
    flex-grow: 1;
    padding: 8px;
    z-index: 1;
    background: #080808;

    > .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      > .groupId {
        display: none;
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }

      > .member {
        color: #FFF;
        text-align: right;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > img {
          display: none;
        }
      }
    }

    > .body {
      margin-top: 21%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 2px;

      > .title {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        opacity: 1;
      }

      > .buyin {
        color: #FFF;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
      }
    }
  }

  > .footer {
    width: 100%;
    background: #080808;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    gap: 2px;
    ${p => p.selected ? `
    padding: 10px 8px;
    height: 52px;
    @media ${MEDIA_DESKTOP} {
      padding: 8px;
      height: 56px; 
    }
    ` : `
    padding: 8px;
    height: 50px;
    @media ${MEDIA_DESKTOP} {
      padding: 8px;
      height: 56px; 
    }
    `}
    > .blind {
      z-index: 1;
      color: #FFF;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        font-size: 13px;
      }
    }
    >.ante-wrapper{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      > .ante {
        color: #B4B4B4;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          font-size: 12px;
        }
      }
    }
    
  }
`
const CategoryWrapper = styled.div`
  padding-top: 16px;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > .item {
    cursor: pointer;
    padding-bottom: 14px;
    border-bottom: 1px solid #25262D;
    flex: 1;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 1px solid #000;
  }
`
const Banner = styled.div`
  width: 100%;
  height: 120px;
  background: gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
    object-fit: cover;
  }
`
const RoomListWrapper = styled.div`
  margin-top: 12px;
  width: 100%;
  padding: 0 8px;
  display: block;
  @media ${MEDIA_DESKTOP} {
    margin-top: 20px;
    padding: 0;
  }

  > .room-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
    z-index: 2;
    @media ${MEDIA_DESKTOP} {
      width: 706px;
      max-width: 706px;
    }

    > .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      @media ${MEDIA_DESKTOP} {
        justify-content: flex-start;
        margin-top: 100px;
      }
      > .wrapper {
        position: relative;

        > .center {
          width: 84px;
          height: 84px;
          object-fit: contain;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        > .bottom {
          width: 100%;
          position: absolute;
          bottom: 3px;
          left: 50%;
          transform: translateX(-50%);
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
        }

        > .background {
          width: 220px;
          height: 220px;
          object-fit: contain;
        }
      }
    }
  }

  > .pc-banner-wrapper {
    width: 266px;
    z-index: 3;
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
    }

    > .pc-banner {
      width: 266px;
      object-fit: contain;
      flex-shrink: 0;
      
    }
  }

`
const TournamentItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 72px;
  border-radius: 2px;
  padding: 0 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) -17.5%, rgba(255, 255, 255, 0.00) 60%), linear-gradient(90deg, #1A1C2B 41.13%, #5B3C25 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 6px;

  > .background {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 290px;
    object-fit: contain;
    z-index: 0;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  > .status-wrapper {
    z-index: 1;
    width: 44px;
    height: 44px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      color: #FFF;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Pretendard;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
    }

    > .background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  > .info {
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;

    > .top {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .title {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      > .time {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;

        > .time {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }

        > span {
          color: #B4B4B4;
          text-align: center;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }
    }

    > .bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .sub-title {
        color: #B4B4B4;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      > .right-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;

        > .participants {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;

          > span {
            color: #E31D1D;
            text-align: center;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
          }

          > .user {
            width: 12px;
            height: 12px;
            object-fit: contain;
          }
        }

        > .prize {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;

          > .title {
            color: #FFF;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.2px;
          }

          > .amount {
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.2px;
            background: linear-gradient(180deg, #FDFAF1 0%, #EDCC72 79.17%, #E2B748 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
`
interface HoldemProps {
  showLogin?: () => void;
}

const Holdem = ({
    showLogin
  }: HoldemProps) => {
  const [conn] = useSocketIsConnect();
  const history = useHistory();
  const {openDialog} = useDialog();
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [ringGroups] = useRingGroupListRefresh();
  const [selectedType, setSelectedType] = useState<'200-1000' | '1000-10000' | '10000-100000' | 'tournament'>('200-1000');
  const [selectRingGroup, setSelectRingGroup] = useState<number>(-1);
  useEffect(() => {
    (async () => {
      if (selectedType !== 'tournament') {
        await requestRingGroupList();
      } else {
        await requestTournamentGroupList();
      }
    })()
    setSelectRingGroup(-1);
    let type = 'red';
    if (selectedType === '200-1000') {
     type = 'blue'
    }else if(selectedType === '1000-10000') {
      type = 'yellow'
    }else if(selectedType === '10000-100000') {
      type = 'red'
    }else if(selectedType === 'tournament') {
      type = 'purple'
    }
  }, [selectedType])

  const handleClickJoin = useCallback(async function (groupId: number) {
    if (!conn.isLogin) {
      showLogin && showLogin();
      return;
    }
    setGlobalLoading(true);
    let r = await requestJoinRingGroup(groupId);
    setGlobalLoading(false);
    if (typeof (r.result) == "number") {
      if (r.result == -1) {
        openDialog({
          title: '오류',
          text: '현재 진행중인 게임이 있어 입장할 수 없습니다.',
          onConfirm: () => {
            location.reload();
          }
        });
      } else
        history.push("/g?id=" + r.result);
    }
  }, [conn.isLogin]);
  
  return <HoldemWrapper>
    <CategoryTabWrapper>
      <div className={'item ' + (selectedType === '200-1000' ? 'selected blue' : '')} onClick={() => {
        setSelectedType('200-1000')
      }}>
        200-1,000
       <TabEffect type={'blue'}/>
      </div>
      <div className={'item ' + (selectedType === '1000-10000' ? 'selected yellow' : '')} onClick={() => {
        setSelectedType('1000-10000')
      }}>
        1,000-10,000
        <TabEffect type={'yellow'}/>
      </div>
      <div className={'item ' + (selectedType === '10000-100000' ? 'selected red' : '')} onClick={() => {
        setSelectedType('10000-100000')
      }}>
        10,000-100,000
        <TabEffect type={'red'}/>
      </div>
    </CategoryTabWrapper>
    <RoomListWrapper>
      <div className='room-list'>
        {
          <>
            {
              (ringGroups && ringGroups.list.length === 0) && <div className='empty'>
                <div className='wrapper'>
                  <img className='center' src='/images/img_3.png' alt='empty_point.png'/>
                  <div className='bottom'>No current available game.</div>
                  <img className='background' src='/images/empty_bg.png' alt='empty'/>
                </div>
              </div>
            }
            {
              (ringGroups && ringGroups.list.length > 0) && <>
                {
                  <RingCardList>
                    {
                      ringGroups?.list.filter(e => {
                        const smallBlind = e.data.blind[0]
                        const bigBlind = e.data.blind[1]
                        if (selectedType === '200-1000') {
                          return (smallBlind >= 200 && bigBlind <= 1000)
                        } else if (selectedType === '1000-10000') {
                          return (smallBlind >= 1000 && bigBlind <= 10000)
                        } else if (selectedType === '10000-100000') {
                          return (smallBlind >= 10000 && bigBlind <= 100000)
                        } else {
                          return false
                        }
                      }).map((e, index) => {
                        const smallBlind = e.data.blind[0]
                        const bigBlind = e.data.blind[1]

                        return <RingCard key={index} selected={selectRingGroup === e.groupId}
                                         onClick={() => {
                                           setSelectRingGroup(e.groupId)
                                         }}>

                          <div className='content'>
                            <div className='header'>
                              <div className='groupId'>
                                {e.groupId}
                              </div>
                              <div className='member'>
                                <img src='/images/User.png'/>{e.totalPlayerCount}/{e.data.maxTableMember}
                              </div>
                            </div>
                            <div className='body'>
                              <div className='title'>
                                Buyin
                              </div>
                              <div className='buyin'>
                                {e.data.minBuyin.toLocaleString()} over
                              </div>
                            </div>
                          </div>
                          <div className='footer'>
                            <div className='blind'>{smallBlind.toLocaleString()} - {bigBlind.toLocaleString()}</div>
                            <div className='ante-wrapper'>
                              <div className='ante'>Ante {e.data.isAnte ? toEasternNumeral(e.data.ante) : 'none'}</div>
                              {
                                e.data.isStraddle && <STRBadge>
                                  <img className='icon' src='/images/ic_str.png'/>
                                </STRBadge>
                              }
                            </div>
                          </div>
                        </RingCard>
                      })
                    }
                  </RingCardList>
                }

                { (ringGroups && ringGroups.list.length > 0) &&
                <EnterButtonWrapper>
                  <EnterButton onClick={() => {
                    handleClickJoin(selectRingGroup)
                  }}>
                    {conn.isLogin ? `Enter`: `Please do login`}
                  </EnterButton>
                </EnterButtonWrapper>
                }
              </>
            }
          </>
        }
      </div>
      <div className='pc-banner-wrapper'>
        <EnterButton onClick={() => {
          handleClickJoin(selectRingGroup)
        }}>
          {conn.isLogin ? `Enter`: `Please do login`}
        </EnterButton>
      </div>
    </RoomListWrapper>
  </HoldemWrapper>
}

export default Holdem;
