import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.img<{
  size?: number
}>`
  width: ${p => p.size || 36}px;
  height: ${p => p.size || 36}px;
  object-fit: contain;
  @media ${MEDIA_DESKTOP} {
    width: ${p => p.size || 140}px;
    height: ${p => p.size || 140}px;
  }
`;

function Emoticon(
  {
    id,
    size
  }: {
    id: number,
    size?: number
  }
) {
  return <Wrapper size={size} src={`/images/emoji/${id}.gif`}/>
}

export default Emoticon;
