import styled from "styled-components";
import {useCallback, useEffect, useRef} from "react";
import {playSFX, Sounds} from "../../utils/sound";
import { throttle} from "lodash";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  position: absolute;
  right: 28px;
  bottom: 94px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  @media ${MEDIA_DESKTOP} {
    bottom: 218px;
    right: 84px;
    gap: 32px;
  }
  > .button-col {
    height: 142px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media ${MEDIA_DESKTOP} {
      height: 385px;
    }
  }
`;

const Gauge = styled.div`
  width: 8px;
  margin: 0px 10px;
  height: 140px;
  background-image: url(/images/bet_gauge_v_bg.svg);
  position: relative;
  @media ${MEDIA_DESKTOP} {
    width: 22px;
    height: 385px;
    background-size: cover;
  }
`;

const Fill = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 0;
  will-change: height;
  background-image: url(/images/bet_gauge_v_fill.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.6));
  pointer-events: none;
  user-select: none;
  @media ${MEDIA_DESKTOP} {
    width: 22px;
    background-size: cover;
  }
`;

const Thumb = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  will-change: bottom;
  left: -10px;
  text-align: center;
  transform: translateY(50%);
  @media ${MEDIA_DESKTOP} {
    left: -22px;
  }
  > .chip {
    position: relative;
    width: 28px;
    height: 28px;
    background-image: url(/images/ic_knob.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      width: 66px;
      height: 66px;
    }

    > .label {
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translate(-50%, 100%);
      padding: 2px 8px;
      border-radius: 15px;
      background: rgba(24, 26, 29, 0.50);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12) inset;
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

const Button = styled.div<{disabled: boolean}>`
  cursor: pointer;
  display: flex;
  width: 66px;
  height: 26px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 3px solid #C90D18;
  background: linear-gradient(180deg, #A90912 5.56%, #F43E34 100%);
  box-shadow: 0px 0.3px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  ${p => p.disabled ? `
  opacity: 0.5;
  `: ``}
  @media ${MEDIA_DESKTOP} {
    width: 160px;
    height: 66px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
    border-radius: 33px;
    border: 6px solid #C90D18;
    background: linear-gradient(180deg, #A90912 5.56%, #F43E34 100%);
    box-shadow: 0px 0.3px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
  }
}
`;

function BetGaugeVertical(
  {
    min,
    max,
    onChange,
    pot,
    stack,
    currentBet,
    callAmount,
    bb
  }: {
    pot: number;
    min: number;
    max: number;
    onChange: (amount: number) => void;
    onCancel: () => void;
    stack: number;
    currentBet: number;
    callAmount: number;
    bb: number;
  }
) {
  const orientation = useScreenOrientation();
  const ref = useRef<HTMLImageElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const fillRef = useRef<HTMLDivElement>(null);
  const prevState = useRef<any>(null);
  const innerAmount = useRef(min);

  const playUp = useCallback(throttle(()=>{
    playSFX(Sounds.SFX_BUY_IN_UP);
  },100),[]);
  const playDown = useCallback(throttle(()=>{
    playSFX(Sounds.SFX_BUY_IN_DOWN);
  },100),[]);

  useEffect(() => {
    if (!ref.current || !thumbRef.current) {
      return;
    }
    const margin = (orientation === 'landscape' ? 32 : 12);
    const gaugeHeight = ref.current.offsetHeight - (margin*2);

    const handleMouseEvent = (e: any) => {
      if (!thumbRef.current || !fillRef.current) {
        return;
      }

      e.stopPropagation();

      if (e.type === 'mousedown' || e.type === 'touchstart') {
        const thumbBottom = Number((thumbRef.current.style.bottom || '0').replace('px', ''));
        if (!prevState.current) {
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;
          prevState.current = {
            thumbBottom: thumbBottom,
            originY: clientY
          };
        }
      } else if (e.type === 'mousemove' || e.type === 'touchmove') {
        if (prevState.current) {
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;
          const dy = prevState.current.originY - clientY;
          const newBottom = Math.max(Math.min(prevState.current.thumbBottom + dy, gaugeHeight), 0);
          thumbRef.current.style.bottom = newBottom + margin + 'px';
          fillRef.current.style.height = newBottom + margin + 'px';

          const portion = Math.max(Math.min(newBottom / gaugeHeight, 1), 0);
          const amount = Math.floor(min + (max - min) * portion);

          if(amount < innerAmount.current){
            playDown();
          }
          if(amount > innerAmount.current){
            playUp();
          }
          innerAmount.current = amount;
          onChange && onChange(amount);
        }
      } else if (e.type === 'mouseup' || e.type === 'touchend') {
        if (prevState.current) {
          prevState.current = null;
        }
      }
    };

    thumbRef.current.addEventListener('mousedown', handleMouseEvent);
    thumbRef.current.addEventListener('touchstart', handleMouseEvent);
    window.addEventListener('mousemove', handleMouseEvent);
    window.addEventListener('touchmove', handleMouseEvent);
    window.addEventListener('mouseup', handleMouseEvent);
    window.addEventListener('touchend', handleMouseEvent);

    return () => {
      if (thumbRef.current) {
        thumbRef.current.removeEventListener('mousedown', handleMouseEvent);
        thumbRef.current.removeEventListener('touchstart', handleMouseEvent);
        window.removeEventListener('mousemove', handleMouseEvent);
        window.removeEventListener('touchmove', handleMouseEvent);
        window.removeEventListener('mouseup', handleMouseEvent);
        window.removeEventListener('touchend', handleMouseEvent);
      }
    };
  }, [min, max, onChange, orientation]);

  useEffect(()=>{
    handleClickButton(min);
  }, [min, orientation])

  const handleClickButton = useCallback((amount: number) => {
    const margin = (orientation === 'landscape' ? 32 : 12);
    if(stack < amount) return;
    playSFX(Sounds.SFX_CLICK_INGAME);
    amount = Math.max(Math.min(amount, max), min);
    if(ref.current){
      const gaugeHeight = ref.current.offsetHeight - (margin*2);
      const portion = Math.max(Math.min((amount-bb) / max, 1), 0);
      const newBottom = Math.max(Math.min(portion * gaugeHeight, gaugeHeight), 0);
      console.log('aa', newBottom, margin)
      thumbRef.current?.style.setProperty('bottom', newBottom + margin  + 'px');
      fillRef.current?.style.setProperty('height', newBottom + margin + 'px');
      onChange && onChange(amount);
    }
  }, [min, max, onChange, stack, orientation,bb]);

  const calculatePotAmount = (type: 0.33 | 0.5 | 1) => {
    return Math.ceil(((pot + callAmount) * type + currentBet) / 100) * 100;
  }
  return <Wrapper ref={ref}>
    <div className='button-col'>
      <Button
        disabled={false}
        onClick={() => handleClickButton(max)}
      >ALL IN</Button>
      <Button
        disabled={min > calculatePotAmount(1)  ||stack < calculatePotAmount(1)}
        onClick={() => handleClickButton(calculatePotAmount(1))}
      >POT</Button>
      <Button
        disabled={min > calculatePotAmount(0.5)  || stack < calculatePotAmount(1)}
        onClick={() => handleClickButton(calculatePotAmount(0.5))}
      >1/2 POT</Button>
      <Button
        disabled={min > calculatePotAmount(0.33) || stack < calculatePotAmount(0.33)}
        onClick={() => handleClickButton(calculatePotAmount(0.33))}
      >1/3 POT</Button>
    </div>
    <Gauge>
      <Fill ref={fillRef}/>
      <Thumb ref={thumbRef}>
        <div className="chip"/>
      </Thumb>
    </Gauge>
  </Wrapper>;
}

export default BetGaugeVertical;
